.input {
  display: flex;
  flex-direction: row;
  /* background-color: whitesmoke; */
  /* border: solid 1px; */
  align-items: center;
  /* padding: 14px 10px 14px 7px;
  border-color: whitesmoke; */

  /* margin-top: 3%; */
  /* border-radius: 5px; */
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
}

.inputText {
  border: solid 0px;
  background-color: whitesmoke;
  padding-left: 10px;
  font-size: 13px;
  outline: none;
  width: 90%;
}

.error {
  margin: 0px;
  text-align: right;
  color: red;
  margin-top: 8px;
  font-size: 12px;
  margin-right: 10px;
}
