.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.dragBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  background-color: #f3f3f3;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.parent {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 50vh;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 10px; */
  background-color: white;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px; */
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.headingContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.heading {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  cursor: pointer;
  background-size: contain;
}
.imageStyle{
  width: 300px;
  height: 200px;
  cursor: pointer;
  background-size: contain;
  margin-bottom:10px;
}

.name {
  margin: 0px;
  font-size: 20px;
  margin-top: 5px;
}

.uploadContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}
.selectBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.select {
  display: none;
}

.uploadButton {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  /* padding: 7px 15px; */
  cursor: pointer;
  border: solid 0px;
  outline: none;
  /* border-radius: 5px;
  font-size: 19px;
  font-weight: 500; */
  border-radius: 0.25rem;
  padding: 0.5rem 2rem;
}

.uploadButton:active {
  opacity: 0.5;
}

.buttonContainer {
  display: flex;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d51e1e;
  color: white;
  border: solid 0px;
  outline: none;
  /* font-weight: 700; */
  /* padding: 10px 35px; */
  border-radius: 0.25rem;
  padding: 0.35rem 2rem;
  /* border-radius: 5px; */
  font-size: 16px;
}
