.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
}

.box1 {
  height: 100%;
  width: 65%;
}

.box2 {
 align-items: center;
 justify-content: center;
 background-color: rgba(255, 255, 255, 0.9);
 box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
 width: 35%;
 max-width: 70%;
 min-width: 70%;
 border-radius: 2rem;
 padding: 1.25rem 2rem 1.25rem 2rem;
 min-height: 95%;
 height: fit-content;
}
.form{
  width: 100%;
}

.inputs {
  width: 100%;
}
.iconContainer1 {
  padding: 0.30rem 1.5rem 0.30rem 1.5rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

.iconContainer2 {
  padding: 0.07rem 1.25rem 0.07rem 1.25rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.button {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  background-color:#272944;
  color: #fff;
}
.button:hover {
  cursor: pointer;
}
.headerText {
  margin-top: -1rem;
  color: white;
  font-weight: 500;
}
.header {
  margin-bottom: 2rem;
  text-align: center;
}
.forgetPassword {
  color: #272944;
}
.forgetPassword:hover {
  cursor: pointer;
}
.logo {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
}
.image {
  margin-left: 1rem;
}
.socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #272944;
  font-weight: 500;
}
.forgetBox {
  display: flex;
  gap: 0.5rem;
}
.loginText {
  color: white;
  font-weight: 500;
}
.loginText:hover {
  cursor: pointer;
}
.storeIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 5%;
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
  }
  .child1 {
    display: none;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .header {
    text-align: center;
  }
  .buttons {
    flex-direction: column;
    align-items: center;
  }
  .button {
    width: 100%;
  }
}
.buttonView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}