.parent {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
    min-width: 900px;
    width: 100vw;
    min-height: 100vh;
    /* min-height: 400px;
    height: 148vh; */
    /* padding: 1.5rem 4rem; */
    height: fit-content;
  }
  
  .child {
    display: flex;
    flex-direction: column;
    width: 600px;
    /* margin-left: 1%; */
    height: 100%;
    background-color: #f3f3f3;
    margin-top: 10px;
    /* padding-left: 2%; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
    /* padding-right: 2%; */
  }
  
  .heading {
    /* font-size: 20px; */
    font-weight: bold;
    color: #272944;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .title {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    margin: 0px;
    margin-top: 3%;
  }
  
  .one {
    /* font-size: 15px; */
    font-weight: bold;
    margin: 0px;
  }
  
  .two {
    /* font-size: 15px; */
    font-weight: bold;
    color: red;
    margin: 0px;
    margin-left: 2px;
  }
  
  .select input {
    /* font-family: "Times New Roman"; */
    font-family: "Poppins" !important;
    font-size: 6px;
  }
  .select{
    color: #000;
    max-width: 100%;
    width: 100%;
    margin-left: auto;
  }
  
  
  .box2 {
    display: flex;
  
    flex-direction: row;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    /* align-items: flex-start !important; */
    /* padding-left: 10px;
    padding-right: 20px;
    align-items: center;
    justify-content: space-between; */
    gap: 1rem;
    justify-content: center;
    /* //space-between  */
  }
  .boxBooking {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    gap: 1rem;
    justify-content: center;
    /* height: 10px; */
  }
  
  .divide {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 30%;
  }
  .inputContainer{
    width: 150px;
  }
  
  .button {
    background-color: #272944;
    /* font-size: 18px; */
    /* font-weight: bold; */
    color: white;
    margin-top: 2rem;
    border: solid 0px;
    outline: none;
    width: 25%;
    align-self: center;
    /* padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px; */
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .headerView {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  
  .closeIcon {
    margin-left: auto;
    cursor: pointer;
  }
  
  .closeIcon:hover {
    transform: scale(1.05);
  }
  