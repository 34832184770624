.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 92vh; */
  /* min-height: 100vh;
  height: fit-content;
  width: 100vw;
  min-width: 900px; */
  width: 100vw;
  height: 100%;
  min-width: 900px;
  margin-bottom: 20;
}

.child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 900px;
  width: 100%;
  height: 100%;
  /* background-color: white !important; */
  margin-top: 0%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 5px;
  padding: 2rem 4rem;
  gap: 1rem;
}

.documentContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 1.5rem;
  /* background-color: white; */
  /* border-radius: 15px;
  height: 100%;
  padding: 20px 20px;
  box-sizing: border-box; */
}

.heading {
  font-size: 18px;
  color: #272944;
  font-weight: 700;
  margin: 0px;
}

.addContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
}

.document {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}

.name {
  margin: 0px;
  font-size: 12px;
  margin-left: 5px;
  color: gray;
}

.allDocuments {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  overflow-y: auto;
}

.allDocuments::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.allDocuments::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.allDocuments::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.allDocuments::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.projectDetails {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 1.5rem;
}

.detailsHeading {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 20px;
}

.data {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* background-color: white;
  border-bottom: 3px solid #f3f3f3; */
  padding: 0.5rem;
  /* border: 1px solid red; */
  box-sizing: border-box;
}

.head {
  margin: 0px;
  /* border: 1px solid blue; */
  /* font-size: 12px; */
}

.text {
  margin: 0px;
  /* width: 80%;
  height: auto; */
  /* font-size: 12px; */
  font-weight: 500;
  /* border: 1px solid black; */
  /* align-items: start;
  overflow-wrap: anywhere; */
  /* wordWrap: "break-word";
   maxWidth: "200px"  */
   /* word-wrap: break-word;
   max-width: 200px; */
}

.uploadContainer {
  display: flex;
  justify-content: center;
  /* flex-direction: row-reverse; */
 margin-top: 2rem;
  width: 100%;
}
.selectBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.select {
  display: none;
}

.uploadButton {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 500;
}
.uploadButton:hover{
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.25s linear;
}
/* .uploadButton:active {
  opacity: 0.5;
} */
.docContainer{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0rem 1rem;
  border-radius: 0.5rem;
}
.projectDataContainer{
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}