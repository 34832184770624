.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  /* height: 90%; */
  height: fit-content;
  min-height: 100vh;
  min-width: 900px;
}
.child {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 98%;
  height: 99%;
  /* background-color: white !important; */
  margin-top: 20px;

  padding: 0px 10px;
  white-space: nowrap;

  box-sizing: border-box;
}

.topBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;

  box-sizing: border-box;
}

.title {
  margin: 0px;
  color: #272944;
  font-size: 22px;
}

.addButton {
  /* display: flex; */
  background-color: #272944;
  color: white;
  /* padding: 7px 25px; */
  outline: none;
  border: solid 0px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
}

.FAQContainer {
  display: flex;
  flex-direction: column;
  border: solid 0.5px;
  width: 900px;
  height: 200px;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
}

.container {
  white-space: pre-wrap;
  margin-top: 5px;
  overflow-y: auto;
  padding: 5px 5px;
}

.heading {
  margin: 0px;
  font-size: 17px;
  color: #272944;
}

.line {
  display: flex;
  width: 100%;
  border: solid 1px lightgray;
  margin-bottom: 10px;
  margin-top: 10px;
}

.text {
  margin: 0px;
}

.container::-webkit-scrollbar {
  width: 4px;

  height: 3px;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.subChild {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;

  box-sizing: border-box;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;
}

.subChild::-webkit-scrollbar {
  width: 8px;

  height: 3px;
}

.subChild::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.subChild::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.subChild::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.firstDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 25px; */
  box-sizing: border-box;
}
.cross {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactForm {
  margin: 0px;
  /* font-size: 25px; */
  /* font-weight: 600; */
}

.line {
  display: flex;
  margin-top: 15px;
  border: solid 1px #dcdcdc80;
}

.text {
  margin: 0px;
  margin-top: 25px;
  /* font-size: 15px; */
  /* font-weight: 600; */
}

.dropDown {
  margin-top: 20px;
}

.apply {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100px;
  padding: 0.5rem 0rem;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background-color: #272944;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  border-radius: 0.25rem;
}
.apply1 {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 17%;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  background-color: #272944;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  border-radius: 0.25rem;
}

.title {
  display: flex;
  flex-direction: row;
  /* padding-left: 10px; */
  margin: 0px;
  margin-top: 3%;
}

.one {
  /* font-size: 15px; */
  /* font-weight: bold; */
  margin: 0px;
}

.two {
  /* font-size: 15px; */
  /* font-weight: bold; */
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.box2 {
  display: flex;

  flex-direction: row;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between;
}

.divide {
  /* display: flex; */
  flex-direction: row;  
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
  gap: 1rem;
}
.button {
  background-color:#272944;
  /* font-size: 18px; */
  font-weight: bold;
  color: white;
  margin-top: 10%;
  border: solid 0px;
  outline: none;
  width: 50%;
  align-self: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px;
}

.buttonsBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2.5rem;
  justify-content: space-around;
  margin-left: 100%;
}
.buttonsBox1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  /* margin-left: 100%; */
}


.delete {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100px;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: #e32636;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  /* margin-right: 10px; */
  border-radius: 0.25rem;
}
