
  .checkbox {
    opacity: 0;
    position: absolute;
  }
  
  .label {
    width: 45px;
    height: 19px;
    background-color:#111;
    display: flex;
    border-radius:50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.1);
  }
  
  .ball {
    width: 18px;
    height: 18px;
    background-color: white;
    position: absolute;
    top: 1px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  /*  target the elemenent after the label*/
  .checkbox:checked + .label .ball{
    transform: translateX(21px);
  }
  
  .fa-moon {
    color: pink;
  }
  
  .fa-sun {
    color: yellow;
  }