.parent {
  margin-top: 2rem;
  width: 60vh;
  min-width: 400px;
  height: fit-content;
  min-height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5rem;
  padding: 1rem;
}

.parent::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.parent::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.parent::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.parent::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  z-index: 1000;
}

.profileContainer {
  display: flex;
  flex-direction: column;
}

.profile {
  margin: 0;
  margin-top: 10px;
  font-size: 14px;
  font-family: "Poppins" !important;
}

.inputField {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 10px;
  border-radius: 8px;
}

.buttonView {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.cancelButton,
.saveButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton {
  background-color: #ccc;
  background-color: red;
  color: white;
}
.saveButton {
  background-color: #272944;
  color: white;
}
.passwordInputContainer{
  border-radius: 5px !important;
}
