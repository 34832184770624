.tabbutt .MuiTabs-indicator {
    height: 3px !important;
    background-color: #272944 !important;
}

.tabbutt .MuiButtonBase-root{
    color: black !important;
    font-size: 0.8rem !important;
    padding-bottom: 0px !important;
    font-weight: 600 !important;
    font-family: 'Poppins' !important;
}
.tabbutt .MuiTab-textColorPrimary {
    padding-bottom: 0px ! important;
    font-weight: 600 !important;
    font-family: 'Poppins' !important;
}
/* .tabbutt .MuiTabs-flexContainer{
background-color: #f3f3f3 !important;
} */

.checkBoxteamWise .MuiFormControlLabel-label {
    font-weight: 600 !important;
    line-height: 1.5;
    /* letter-spacing: 0.00938em; */
    font-size: 0.8rem !important;
    font-family: 'Poppins' !important;
    color: #272944 !important;
}