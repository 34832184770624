.parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 900px;
    height: auto;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  button:hover {
    transform: scale(1.02);
    transition: 0.2s ease-in-out;
  }
  