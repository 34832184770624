body {
  margin: 0;
  box-sizing: border-box;

  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8rem !important;
  /* overflow: hidden; */
}

html,
body,
#root,
.App {
  height: 100%;
}

code {

    font-family: "Poppins" !important;
}
