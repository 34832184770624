.parent {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  min-width: 900px;
  width: 100vw;
  min-height: 100vh;
  /* min-height: 400px;
  height: 148vh; */
  /* padding: 1.5rem 4rem; */
  height: fit-content;
}

.child {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* margin-left: 1%; */
  height: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
  /* padding-left: 2%; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* padding-right: 2%; */
}

.heading {
  /* font-size: 20px; */
  font-weight: bold;
  color: #272944;
  margin-top: 10px;
  margin-left: 10px;
}

.title {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
  margin-top: 3%;
}

.one {
  /* font-size: 15px; */
  font-weight: bold;
  margin: 0px;
}

.two {
  /* font-size: 15px; */
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.select input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.select {
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}

.box2 {
  display: flex;

  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between; */
  gap: 1rem;
  justify-content: space-between;
}

.divide {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.inputContainer {
  width: 150px;
}

.button {
  background-color: #272944;
  /* font-size: 18px; */
  /* font-weight: bold; */
  color: white;
  margin-top: 2rem;
  border: solid 0px;
  outline: none;
  width: 25%;
  align-self: center;
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px; */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
}

.closeIcon:hover {
  transform: scale(1.05);
}

/* Add your desired background color and font size here */
.customfacebooklogin {
  background-color: #3b5998; /* Facebook blue color */
  color: #fff; /* Text color for the button */
  font-size: 1px; /* Adjust font size as needed */
  padding: 1px; /* Add padding to make the button more visually appealing */
  border-radius: 5px; /* Add rounded corners to the button */
  cursor: pointer; /* Show pointer cursor on hover */
  /* Add any other custom styles you want */
}

/* Optional: To center the login button on the page */
.facebooklogincontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.circularimage {
  width: 50px; /* Set the desired width of the circular image */
  height: 50px; /* Set the desired height of the circular image */
  border-radius: 50%; /* Make the border-radius 50% to create a circular shape */
  overflow: hidden; /* Hide any parts of the image that go beyond the circular shape */
}

.buttoncancel {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: rgb(39, 41, 68);
  color: white;
  border: solid 0px;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 2px;
  width: 55px;
  height: 25px;
  font-size: 12px;
  margin: 0px 10px 0px 10px;
  /* margin-top: -0.5rem; */
}
.customselect {
  width: 99%;
  /* Add any other styles you want to apply to the select element */
}

button svg {
  width: 1em; /* Adjust the size of the SVG icon */
  height: auto;
  margin-right: 0.5rem;
}

.accordionContainer {
  margin-top: 10px;
  background-color: "#f9f9f9";
  padding: "10px";
}