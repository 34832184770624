@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap');


.side-container{
    background: rgb(0, 48, 61);
    width: 220px;
    height: 100vh;
    position: relative;
    color: white;
    transition: 0.4s;
    overflow-y: scroll
}

.side-container-NX {
    width: 90px;
    height: 100vh;
    overflow-x: hidden;
}

.name {
    text-align: center;
}

.nav-upper, nav-heading, nav-menu,
menu-item {
    display: grid;
}

.nav-menu {
    grid-template-rows: repeat(7, 1fr);
    margin-top: 50px;
    font-size: 90%;
}

.nav-heading {
    grid-template-columns: 2fr, 1fr;
    grid-template-rows: 1fr;
    height: 65px;
    /* z-index: 999; */

}
.nav-brand {
    display: flex;
}
.nav-brand img {
    width: 80%;
    height: auto;
    padding: 20px 10px;
}
.hamburger {
    position: absolute;
    right: 25px;
    top: 0;
    background: none;
    border: none;
    cursor:pointer ;
    margin: 0 -35px;
    /* background-color: #fe355f; */
    border: 2px solid white ;
    padding: 20px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    scale: 60%;
}

.hamburger span{
    display: block;
    margin-top: 5px;
    background-color: white;
    border-radius: 15px;
    height: 4px;
    width: 30px;
    transition: 0.4s;
    transform: translate(-3px, -3px);
}

.hamburger-out:hover span:nth-child(1) {
    width: 25px;
    transform: translate(-3px, 9px) rotate(30deg);
}
.hamburger-out:hover span:nth-child(2) {
    display: none;
}
.hamburger-out:hover span:nth-child(3) {
    width: 25px;
    transform: translate(-3px, -9px) rotate(-30deg);
}

.hamburger-in:hover span:nth-child(1) {
    width: 25px;
    transform: translate(0px, 9px) rotate(-30deg);
}
.hamburger-in:hover span:nth-child(2) {
    display: none;
}   
.hamburger-in:hover span:nth-child(3) {
    width: 25px;
    transform: translate(0px, -9px) rotate( 30deg);
}

.menu-item {
    height: 50px;
    width: 90%;
    display: flex;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    margin: 15px 20px -10px 10px;
    border-radius: 10px;
    position: relative;
    transform: translateY(-60px);
    font-family: 'Poppins', sans-serif ;
    /* scale: 95%%; */
    
}

.menu-item:hover {
	/* background: #fe355f; */
    color: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* overflow-wrap: anywhere; */
}

.menu-item img {
    width: 30px;
    padding: 0;
    /* filter: invert(); */
    margin-left: 10px;
}

.white-img {
    filter: invert();
}  

.menu-item-NX {
    margin-top: 40;
    /* margin: auto; */
    width: 4.5em;
}

.menu-item:hover > .tooltip_nav {
    display: block;
    /* z-index: 1800; */
}

.menu-item p {
    margin-top: 1rem;
    margin-left: 1.5rem;
    font-weight: 500;
}

/* .tooltip_nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  position: absolute;
  padding: 5px;
  background-color: #fff;
  color: #000;
  left: -35px;
  bottom: -9px;
} */
.tooltip_nav {
    display: none;
    position: absolute;
    left: -35px;
    bottom: -9px;
    width: 100vw;
    height: auto;
    padding: 0px 35px;
    border-radius: 15px 15px 15px 0;
    white-space: nowrap;
    /* word-break: break-all; */
    /* border: 2px solid rgb(26, 43, 60); */
    background-color: white;
    color: black;
    
    /* text-wrap: wrap;  */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* text-align: center !important; */
}

/* .tooltip_nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 1;
} */
  
.light-mode {
    background-color: #fff;
}
.menu-text-with-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
  }
  
  .menu-arrow-icon {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .line {
    display: flex;
    flex-direction: row;
    width: 95%;
    border: solid;
    border-color: white;
    border-width: 0.9px;
    align-self: center;
    margin-top: -10px;
    margin-bottom: 10px;
  }