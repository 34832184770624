/* .css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
    margin-top: 50px !important;
    color: black !important;
}

.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
    width: calc(64px + 1px);
    margin-top: 50px !important;
}
.css-heg063-MuiTabs-flexContainer {
    background-color: #f3f3f3 !important;
}

.css-1ujnqem-MuiTabs-root {
    min-height: none !important;

}
.css-10hburv-MuiTypography-root {
  font-family: "none","none","none",none !important;
}

.MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root.Navbar_button__14mxG.Navbar_active__2pjWC.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root {
  background-color: #279f9f !important;
  color: white !important;
}
.css-10hburv-MuiTypography-root {
  font-family: "Poppins"!important;
}
.css-wa5fuu-MuiListItemIcon-root {
  margin-right: 8px !important;
} */
/* .container .Mui-box{
  margin-top: 50px !important;
} */
.containerSideBar .MuiDrawer-paper{
  /* margin-top: 100px; */
  margin-top: auto;
}
.containerSideBar .MuiListItemIcon-root{
  margin-right: 10px !important;
  /* margin-top: 20px; */
}

.containerSideBar .css-10hburv-MuiTypography-root {
  font-family: "Poppins"!important;
  font-weight: bold !important;
}
.containerSideBar .MuiListItemText-primary
{
  font-family: "Poppins"!important;
  font-weight: bold !important;
  font-size: 0.8rem !important;
}