.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 83.6%;
  min-height: 400px;
  min-width: 900px;
  padding: 0px 1.5%;
  box-sizing: border-box;
  /* background-color: #363853; */
}
.parentp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 77.6%;
  min-height: 400px;
  min-width: 900px;
  padding: 0px 1.5%;
  box-sizing: border-box;
  /* background-color: #363853; */
}

.child {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 100%;
  height: 93%;
  background-color:white !important;
  margin-top: 0;
  padding-bottom: 2%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
}

.topBar {
  min-width: 900px;
  width: 100%;
  /* background-color: white !important; */
  display: flex;
  /* margin: 0px auto; */
  /* padding: 8px 0px; */
  align-self: center;
  /* margin-top: 7px; */
}

.table {
  border-spacing: 0px;
}

.td {
  display: flex;
  height: 42px;
  min-width: 150px;
  background-color: white;
  /* border-bottom: 5px solid #f3f3f3; */
  align-items: center;
  padding: 0px 50px;
  justify-content: flex-start;
  max-width: 150px;
  overflow: auto;

  box-sizing: border-box;
}
.tdSmall {
  display: flex;
  height: 42px;

  background-color: white;
  /* border-bottom: 5px solid #f3f3f3; */
  padding-bottom: 5px;
  align-items: center;
  /* padding: 0px 30px; */
  /* justify-content: flex-start; */
  max-width: 200px;
  min-width: 160px;
  overflow: auto;
  box-sizing: border-box;
}

.sticky {
  display: flex;
  background-color: white;
  position: sticky;
  height: 42px;
  left: 0;
  position: -webkit-sticky;
  /* border-bottom: 5pt solid #f3f3f3; */
  min-width: 90px;
  padding-right: 0px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  /* border-right: solid 0.3px lightgray;
  border-left: solid 0.3px lightgray; */
}

.td::-webkit-scrollbar {
  display: none;
}

.button {
  margin-top: 20%;
  width: 10%;
}

.scrollData {
  display: flex;
  width: 100%;
  border: solid;
}

.count {
  position: absolute;
  bottom: 0;
  /* margin-top: 10px; */
  min-width: 900px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #484b49;
  padding: 0px 30px;
}

.countContainer {
  display: flex;
  flex-direction: row;
}

.selectContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 2px;
}

.clearFilter {
  display: flex;
  /* border: dashed 1px white; */
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin: 0px;
  cursor: pointer;
  color: #000 !important; 
  /* color: white; */
  /* background-color: #484b49; */
  /* background-color: #495057;
  color: white !important; */
}

.clearFilterEvent {
  /* Button dimensions and spacing */
  display: inline-block;
  padding: 5px 10px;
  /* margin-bottom: 15px; */
  
  /* Button appearance */
  background-color: #272944;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  
  /* Button effects on hover and focus */
  transition: background-color 0.3s;
}
.clearFilterEvent:hover {
  background-color: #151624;
}

.clearFilterEvent:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

.clearText {
  margin: 0px;
  cursor: pointer;
  color: white;
}

.countHead {
  /* margin: 0px;
  font-size: 1rem;
  color: white; */
  margin-top:10px;
  font-size: 0.9rem;
}

.number {
  margin: 0px;
  font-size: 1rem;
  color: white;
  margin-left: 10px;
}

.child::-webkit-scrollbar {
  width: 10px;

  height: 10px;
}

.child::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.child::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.child::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.nextButton {
  background-color: #272944;
  color: white;
  outline: none;
  border: solid 0px;
  padding: 3px 0px;
  width: 70px;
}

.previousButton {
  background-color: #4fce5d;
  color: white;
  outline: none;
  border: solid 0px;
  padding: 3px 0px;
  width: 70px;
}

.branchButton {
  display: flex;
  /* margin-left: 25px; */
  background-color: #272944;
  color: white;
  outline: none;
  border: solid 0px;
  padding: 5px 5px;
  border-radius: 5px;
}

.infiniteScroll::-webkit-scrollbar {
  width: 10px;

  height: 10px;
}

.infiniteScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.infiniteScroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.infiniteScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.addBox {
  flex-direction: row;
  /* background-color: #272944; */
  background-color: #495057;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.dropDownSelecct {
  flex-direction: row;
  /* background-color: #606060; */
  background-color: #272944;
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px black ;
    border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.addBox_api_lead {
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 61%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.dropDownSelecct_api_lead {
  flex-direction: row;
  background-color: #606060;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 58%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px black ;
    border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.footer_table {
  display: flex;
  width: 100%;
  align-items: center;
  /* background-color: #484b49; */
  justify-content: space-between;
  /* margin-top: 0.5rem; */
  /* background-color: #e9ecef;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem; */
  margin: -0.4rem;
}
.nSelecct_api_lead{
  flex-direction: row;
  color: black;
  /* font-size: 13px; */
  padding: 2px 10px;
  height: 61%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 1px black ;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.nSelecct{
  flex-direction: row;
  color: black;
  font-size: 13px;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 1px black ;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}