.parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 900px;
    /* margin-top: 8px; */
    height: fit-content;
    position: relative;
    min-height: 91.5vh;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: white !important; */
    padding: 20px 30px;
    box-sizing: border-box;
    font-family: "Poppins" !important;
    margin-bottom: 100px;
  }
  
  .historyLink {
    cursor: pointer;
    color: #272944;
  }
  
  .button {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    background-color: #272944;
    color: white;
    border: solid 0px;
    outline: none;
    /* font-size: 0.7rem; */
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 0.5rem;
    width: 100px;
    margin-top: -0.5rem;
  }
  
  .buttonSecondary {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    background-color: #fff;
    color: black;
    border: solid 2px #272944;
    outline: none;
    /* font-size: 0.7rem; */
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 0.5rem;
    width: 100px;
  }
  