.select input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.select{
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}
