.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
  /* min-height: 100vh;
  width: 100vw;
  min-width: 900px; */
  width: 100vw;
  height: fit-content;
  min-width: 900px;
}

.child {
  display: flex;
  flex-direction: row;
  min-width: 900px;
  width: 100%;
  height: 100%;
  margin-top: 0%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 5px;
  padding: 0 1rem;
  gap: 1rem;
  padding-bottom: 1rem;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  width: 75%;
  /* margin: 1rem 0 2rem 0; */
}

.contactDetails {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 100%; */
  /* padding: 5px 15px; */
  padding: 0rem 0 ;
  border-radius: 0.5rem;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-top: 15px;
  align-items: center;
}

.nameDiv {
  display: flex;
  flex-direction: column;

  width: 90%;
}

.nameDiv::-webkit-scrollbar {
  display: none;
}

.name {
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
}
.customerEmail {
  font-size: 12px;
  margin: 0px;
  color: #272944;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.data {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  /* border-bottom: 3px solid #f3f3f3; */
  padding: 0.25rem 0;
}

.heading {
  margin: 0px;
  /* font-size: 12px; */
}

.text {
  margin: 0px;
  /* font-size: 12px; */
  font-weight: 500;
}

.secondContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  padding-top: 10px;
}

.emailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 20px;
  margin-bottom: 10px;
}

.email {
  margin: 0px;
  font-size: 14px;
  color: #272944;
  margin-left: auto;
  font-weight: 600;
  margin-top: 5px;
}

.taskDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  margin-top: 2.5rem;
  box-sizing: border-box;
  /* padding: 0px 5px;
  padding-left: 35px; */
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tableContainer {
  width: 100%;
  height: 100%;
  /* height: 250px; */
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: auto;
}

.table {
  width: 100%;
}

.head {
  background-color: #272944;
}

.th {
  /* align-items: center;
  justify-content: center; */
  color: white;
  margin: 0px;
  /* font-size: 12px; */
  font-weight: 400;
  border: solid 0px;
  padding: 0.5rem 1rem;
}

.td {
  /* text-align: center; */
  background-color: white;
  border-bottom: 1px solid #f3f3f3;
  padding: 0.5rem 1rem;
  /* font-size: 13px; */
  /* font-weight: 500; */
}

.task {
  margin: 0px;
  font-size: 15px;
  font-weight:600;
  margin-top: 80px;
}

.notesContainer {
  /* display: flex;
  flex-direction: row; */
  width: 25%;
  height: 100%;
  /* border-radius: 0.5rem; */
  /* padding: 1rem; */
  /* align-items: center; */
}

.childNotes {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* margin-top: 25px; */
  width: 100%;
  height: 100%;
  background-color: white;
  /* border-radius: 10px; */
  box-sizing: border-box;
  /* padding: 25px 10px; */
}

.notesSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 30%; */
  /* min-height: 250px; */
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.notesHead {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* padding: 5px 10px; */
}

.notes {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
}

.attachmentContainer {
  display: flex;
  flex-direction: column;
  height:100px;
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  white-space: normal;
  height: 100px;
  width: 100%;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

.attachmentItems {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.noteDisplay {
  display: flex;
  /* width: 97%; */
  /* flex-direction: row; */
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */
  margin-top: 0.5rem;
  border-bottom: 2px solid #f3f3f3;
}

.box {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  /* max-width: 200px; */
  /* width: 60%; */
  height: 100%;
  width: 100%;
  /* overflow-x: auto; */
}
.innerBox {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: start; */
  padding: 5px 10px;
}
.dateAndCreatedByBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.createdBy {
  font-size: 12px;
}
.note {
  /* margin: 0px; */
  margin: auto;
  /* font-size: 12px; */
  /* font-size: 12px; */
  /* font-weight: 500; */
  /* margin-left: 10px; */
  width: 100%;
}

.container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.noContent {
  margin: 0px;
  text-align: center;
  color: #272944;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}

.box::-webkit-scrollbar {
  display: none;
}

.date {
  margin: 0px;
  font-size: 12px;
  color: #272944;
}

.attachmentSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 80%; */
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* min-height: 250px; */
  /* margin-top: 10px; */
}

.ownerButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 30%;
  color: white;
  background-color: #272944;
  margin-top: -10px;
  margin-bottom: 5px;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.dropdownButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  width: 100%;
  color: white;
  background-color: #272944;
  margin-top: -10px;
  margin-bottom: 5px;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.attachmentBox {
  display: flex;
  flex-direction: row;
  /* max-width: 300px; */
  width: 100%;
  align-items: center;
  /* overflow-x: auto; */
  /* height: fit-content; */
  /* padding: 9px 5px;
  margin-top: 10px; */
  margin-top: 1rem;
  /* overflow-y: hidden; */
  gap: 1rem;
  /* border: 1px solid #fff; */
}

.attachmentBox::-webkit-scrollbar {
  display: none;
}
.addBox {
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  /* height: 95%; */
  /* align-items: center;
  justify-content: space-evenly; */
  border: solid 0px;
  border-radius: 0.25rem;
  outline: none;
  width: 4rem;
  margin-bottom: 1rem;
}
.addBoxBlk {
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  /* padding: 0.25rem 0.5rem; */
  /* height: 95%; */
  /* align-items: center;
  justify-content: space-evenly; */
  border: solid 0px;
  border-radius: 0.25rem;
  outline: none;
  width: 2.5rem;
  margin-bottom: 1rem;
}
.backButtonContainer{
  width: 100%;
  /* border: 2px solid black;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start; */
  padding: 1rem 1rem 0 1rem;
}
