.parent {
  width: 100vw;
  min-width: 900px;
  height: 90vh;
  position: fixed;
  top: 0;
  /* left: 0; */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  flex-direction: column;
}

.loader{
  margin-right: 10%;
  margin-bottom: 5%;
}

.spinner {
  width: 90px;
  height: 90px;
  border: 4px solid #ccc;
  border-top-color: #272944;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.innerSpinner {
  width: 100%;
  height: 100%;
  background-image: url("../../Assets/Images/loaderImages.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border-radius: 50%;
  background-color: none;
  animation: spinInner 1s linear infinite;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinInner {
  to {
    transform: rotate(-360deg);
  }
}