.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  outline: none;
  border: solid 1px;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 12px;
}
