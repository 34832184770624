.topBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 900px;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin: 0.5rem 1rem;
  padding: 0.5rem 0rem;
}

.statusBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  border: solid 1px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.statusText {
  margin: 0px;
  font-size: 15px;
}

.line {
  width: 0px;
  height: 20px;
  border: solid 1px gray;
  margin-left: 10px;
  margin-right: 5px;
}

.box {
  display: flex;
  flex-direction: row;
  width: 45%;
  height: 100%;
  align-items: center;
  justify-content: end;
  padding: 0px 5px;
  margin-left: auto;
}

.searchBox {
  display: flex;
  flex-direction: row;
  height: 95%;
  width: 40%;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  border: solid 1px gray;
  border-radius: 15px;
  padding: 0px 8px;
}


/* .searchBox {
  position: fixed;
  top: 21%;
  left: 69%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  height: 35px;
  width: 20%;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  border: solid 1px gray;
  border-radius: 15px;
  padding: 0px 8px;
  z-index: 999;
}  */

.searchText {
  border: solid 0px;
  outline: none;
  width: 90%;
}

.addBox {
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  margin: 10px;
}

.arrowBox {
  display: flex;
  width: 6%;
  height: 70%;
  border: solid 1px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  z-index: 3;
  margin: 10px;
}

.arrowBox:hover {
  transform: scale(1.05);
}

.owner {
  /* margin: 0px;
  font-size: 15px;
  font-weight: bold; */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  /* height: 95%; */
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  /* margin: 10px; */
}

.innerChild {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.icon {
  cursor: pointer;
}

.columnManage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.tooltiptext {
  visibility: hidden;
  background-color: #272944;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  margin-top: 70px;
  margin-right: 25px;

  /* Position the tooltip */
  position: absolute;
  z-index: 3;
}

.columnManage:hover .tooltiptext {
  visibility: visible;
}

.refreshIcon {
  display: flex;
  background-color: #272944;
  padding: 7px 9px;
  border-radius: 5px;
}

.leadContainer {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 5px;
  margin-left: auto;
}

.leadBox {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
}

.leadText {
  margin: 0px;
  color: #272944;
  font-size: 13px;
}

.checkbox{
  margin-top: -0.75rem;
}