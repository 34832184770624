@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap);
body {
  margin: 0;
  box-sizing: border-box;

  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8rem !important;
  /* overflow: hidden; */
}

html,
body,
#root,
.App {
  height: 100%;
}

code {

    font-family: "Poppins" !important;
}

.Navbar_navbar__3ecOR {
  min-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  padding-left: 1vw;
  padding-right: 2vw;
  box-sizing: border-box;
  min-width: 900px;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Navbar_logo__3zrWk {
  width: 13%;
  min-width: 90px;
  margin: 10px 0px;
}

.Navbar_button__1wd4a {
  background-color: transparent;
  margin-left: 2%;
  border: solid 0px;
  outline: none;
  color: #191919;
  font-weight: bold;
}

button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Navbar_text__1va0J {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color:white;
  font-weight: bold;
  margin: 0%;
  /* margin-left: auto; */
  align-items: center;
  justify-content: space-between;
}

.Navbar_active__1Or_s {
  background: #272944;
  color: white;
  font-weight: bold;
}

.Navbar_line__3u_xL {
  width: 0px;
  height: 22px;
  border: solid 1px lightgrey;
  margin: auto 18px;
}

.Navbar_logOutView__DWAMc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Navbar_logOut__2LTHU {
  display: flex;
  align-items: center;
  justify-content: center;
  background:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,28,34,0.87718837535014) 51%, rgba(0,151,255,0.3561799719887955) 100%)";
  color: #272944;
  font-weight: bold;
  outline: none;
  border: solid 1px #272944;
  padding: 2px 8px 2px 8px;
}

.Navbar_image__Qabgt {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 13px;
  cursor: pointer;
}

.Navbar_userDetailClosed__1K-Z- {
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  -webkit-animation: Navbar_hideDiv__3qTqo 0.3s ease-in;

          animation: Navbar_hideDiv__3qTqo 0.3s ease-in;
  -webkit-transform: translateX(270px);
          transform: translateX(270px);
  z-index: 10;
}

.Navbar_userDetailExpand__3lbNu {
  flex-direction: column;
  display: flex;

  position: relative;
  align-items: center;
  justify-content: center;
  -webkit-animation: Navbar_translate__1Jr70 0.3s ease-in;
          animation: Navbar_translate__1Jr70 0.3s ease-in;
  z-index: 10;
}

@-webkit-keyframes Navbar_translate__1Jr70 {
  from {
    -webkit-transform: translateX(270px);
            transform: translateX(270px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes Navbar_translate__1Jr70 {
  from {
    -webkit-transform: translateX(270px);
            transform: translateX(270px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes Navbar_hideDiv__3qTqo {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  to {
    -webkit-transform: translateX(270px);
            transform: translateX(270px);
  }
}

@keyframes Navbar_hideDiv__3qTqo {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  to {
    -webkit-transform: translateX(270px);
            transform: translateX(270px);
  }
}

.ProfileImageModal_overlay__7wd0i {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.ProfileImageModal_parent__183Q0 {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 50vh;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
}

.ProfileImageModal_headingContainer__2ZN4s {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ProfileImageModal_heading__3FMlO {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
}

.ProfileImageModal_detailContainer__2HRz6 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ProfileImageModal_image__32SgE {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  cursor: pointer;
  background-size: contain;
}
.ProfileImageModal_imageStyle__whjsu{
  width: 300px;
  height: 200px;
  cursor: pointer;
  background-size: contain;
  margin-bottom:10px;
}

.ProfileImageModal_name__34eq7 {
  margin: 0px;
  font-size: 20px;
  margin-top: 5px;
}

.ProfileImageModal_uploadContainer__3a1Ap {
  display: flex;
  flex-direction: row-reverse;
  /* margin-top: 10px; */
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 1rem;
  gap: 1rem;

  margin-top: auto;
  width: 100%;
}
.ProfileImageModal_selectBox__ly7bY {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.ProfileImageModal_select__zyQgF {
  display: none;
}

.ProfileImageModal_uploadButton__1F6Sf {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 5px;
  /* font-size: 19px; */
  font-weight: 500;
}

.ProfileImageModal_uploadButton__1F6Sf:active {
  opacity: 0.5;
}

.ProfileImageModal_buttonContainer__BL40q {
  display: flex;
}

.ProfileImageModal_button__3VXjL {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d51e1e;
  color: white;
  border: solid 0px;
  outline: none;
  font-weight: 700;
  padding: 0.35rem 1rem;
  border-radius: 5px;
  font-size: 15px;
}

.Loading_parent__kIZ__ {
  width: 100vw;
  min-width: 900px;
  height: 90vh;
  position: fixed;
  top: 0;
  /* left: 0; */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  flex-direction: column;
}

.Loading_loader__lsrEM{
  margin-right: 10%;
  margin-bottom: 5%;
}

.Loading_spinner__3an_H {
  width: 90px;
  height: 90px;
  border: 4px solid #ccc;
  border-top-color: #272944;
  border-radius: 50%;
  -webkit-animation: Loading_spin__3BHK8 2s linear infinite;
          animation: Loading_spin__3BHK8 2s linear infinite;
}

.Loading_innerSpinner__1QQW2 {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/loaderImages.bc078e46.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border-radius: 50%;
  background-color: none;
  -webkit-animation: Loading_spinInner__2TxnJ 1s linear infinite;
          animation: Loading_spinInner__2TxnJ 1s linear infinite;
}

.Loading_logo__x0-F0 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}


@-webkit-keyframes Loading_spin__3BHK8 {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes Loading_spin__3BHK8 {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes Loading_spinInner__2TxnJ {
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes Loading_spinInner__2TxnJ {
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
.Backdrop_backdrop__3ZRss {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    z-index: 1000;
}

  .DarkModeSwitch_checkbox__38wAN {
    opacity: 0;
    position: absolute;
  }
  
  .DarkModeSwitch_label__nO4V_ {
    width: 45px;
    height: 19px;
    background-color:#111;
    display: flex;
    border-radius:50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .DarkModeSwitch_ball__3oVAw {
    width: 18px;
    height: 18px;
    background-color: white;
    position: absolute;
    top: 1px;
    left: 2px;
    border-radius: 50%;
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  }
  
  /*  target the elemenent after the label*/
  .DarkModeSwitch_checkbox__38wAN:checked + .DarkModeSwitch_label__nO4V_ .DarkModeSwitch_ball__3oVAw{
    -webkit-transform: translateX(21px);
            transform: translateX(21px);
  }
  
  .DarkModeSwitch_fa-moon__15L5m {
    color: pink;
  }
  
  .DarkModeSwitch_fa-sun__3vYE3 {
    color: yellow;
  }
.common_parent__3k-YA {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 83.6%;
  min-height: 400px;
  min-width: 900px;
  padding: 0px 1.5%;
  box-sizing: border-box;
  /* background-color: #363853; */
}
.common_parentp__2ZXrC {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 77.6%;
  min-height: 400px;
  min-width: 900px;
  padding: 0px 1.5%;
  box-sizing: border-box;
  /* background-color: #363853; */
}

.common_child__GAKFb {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 100%;
  height: 93%;
  background-color:white !important;
  margin-top: 0;
  padding-bottom: 2%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
}

.common_topBar__2YONF {
  min-width: 900px;
  width: 100%;
  /* background-color: white !important; */
  display: flex;
  /* margin: 0px auto; */
  /* padding: 8px 0px; */
  align-self: center;
  /* margin-top: 7px; */
}

.common_table__2yp7F {
  border-spacing: 0px;
}

.common_td__sZt_e {
  display: flex;
  height: 42px;
  min-width: 150px;
  background-color: white;
  /* border-bottom: 5px solid #f3f3f3; */
  align-items: center;
  padding: 0px 50px;
  justify-content: flex-start;
  max-width: 150px;
  overflow: auto;

  box-sizing: border-box;
}
.common_tdSmall__2hSVe {
  display: flex;
  height: 42px;

  background-color: white;
  /* border-bottom: 5px solid #f3f3f3; */
  padding-bottom: 5px;
  align-items: center;
  /* padding: 0px 30px; */
  /* justify-content: flex-start; */
  max-width: 200px;
  min-width: 160px;
  overflow: auto;
  box-sizing: border-box;
}

.common_sticky__1qdOf {
  display: flex;
  background-color: white;
  position: sticky;
  height: 42px;
  left: 0;
  position: -webkit-sticky;
  /* border-bottom: 5pt solid #f3f3f3; */
  min-width: 90px;
  padding-right: 0px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  /* border-right: solid 0.3px lightgray;
  border-left: solid 0.3px lightgray; */
}

.common_td__sZt_e::-webkit-scrollbar {
  display: none;
}

.common_button__3e6mJ {
  margin-top: 20%;
  width: 10%;
}

.common_scrollData__3EYd7 {
  display: flex;
  width: 100%;
  border: solid;
}

.common_count__2Lb0X {
  position: absolute;
  bottom: 0;
  /* margin-top: 10px; */
  min-width: 900px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #484b49;
  padding: 0px 30px;
}

.common_countContainer__2B42Q {
  display: flex;
  flex-direction: row;
}

.common_selectContainer__1zaNM {
  display: flex;
  flex-direction: row;
  padding-bottom: 2px;
}

.common_clearFilter__2kgT7 {
  display: flex;
  /* border: dashed 1px white; */
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin: 0px;
  cursor: pointer;
  color: #000 !important; 
  /* color: white; */
  /* background-color: #484b49; */
  /* background-color: #495057;
  color: white !important; */
}

.common_clearFilterEvent__3-d50 {
  /* Button dimensions and spacing */
  display: inline-block;
  padding: 5px 10px;
  /* margin-bottom: 15px; */
  
  /* Button appearance */
  background-color: #272944;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  
  /* Button effects on hover and focus */
  transition: background-color 0.3s;
}
.common_clearFilterEvent__3-d50:hover {
  background-color: #151624;
}

.common_clearFilterEvent__3-d50:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

.common_clearText__2xJPq {
  margin: 0px;
  cursor: pointer;
  color: white;
}

.common_countHead__JEHjN {
  /* margin: 0px;
  font-size: 1rem;
  color: white; */
  margin-top:10px;
  font-size: 0.9rem;
}

.common_number__25QjC {
  margin: 0px;
  font-size: 1rem;
  color: white;
  margin-left: 10px;
}

.common_child__GAKFb::-webkit-scrollbar {
  width: 10px;

  height: 10px;
}

.common_child__GAKFb::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.common_child__GAKFb::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.common_child__GAKFb::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.common_nextButton__3_8fw {
  background-color: #272944;
  color: white;
  outline: none;
  border: solid 0px;
  padding: 3px 0px;
  width: 70px;
}

.common_previousButton__3x5kd {
  background-color: #4fce5d;
  color: white;
  outline: none;
  border: solid 0px;
  padding: 3px 0px;
  width: 70px;
}

.common_branchButton__6lkU0 {
  display: flex;
  /* margin-left: 25px; */
  background-color: #272944;
  color: white;
  outline: none;
  border: solid 0px;
  padding: 5px 5px;
  border-radius: 5px;
}

.common_infiniteScroll__3RuRg::-webkit-scrollbar {
  width: 10px;

  height: 10px;
}

.common_infiniteScroll__3RuRg::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.common_infiniteScroll__3RuRg::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.common_infiniteScroll__3RuRg::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.common_addBox__2X4bH {
  flex-direction: row;
  /* background-color: #272944; */
  background-color: #495057;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.common_dropDownSelecct__2uzPd {
  flex-direction: row;
  /* background-color: #606060; */
  background-color: #272944;
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px black ;
    border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.common_addBox_api_lead__20jH1 {
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 61%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.common_dropDownSelecct_api_lead__2pV3O {
  flex-direction: row;
  background-color: #606060;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 58%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px black ;
    border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.common_footer_table__1R5k1 {
  display: flex;
  width: 100%;
  align-items: center;
  /* background-color: #484b49; */
  justify-content: space-between;
  /* margin-top: 0.5rem; */
  /* background-color: #e9ecef;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem; */
  margin: -0.4rem;
}
.common_nSelecct_api_lead__34LB5{
  flex-direction: row;
  color: black;
  /* font-size: 13px; */
  padding: 2px 10px;
  height: 61%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 1px black ;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.common_nSelecct__2-ATV{
  flex-direction: row;
  color: black;
  font-size: 13px;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 1px black ;
  border-radius: 5px;
  outline: none;
  margin-left: 5px;
}
.TopBar_topBar__1S-Z1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 900px;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin: 0.5rem 1rem;
  padding: 0.5rem 0rem;
}

.TopBar_statusBox__3jWBv {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  border: solid 1px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.TopBar_statusText__3xr-O {
  margin: 0px;
  font-size: 15px;
}

.TopBar_line__z2779 {
  width: 0px;
  height: 20px;
  border: solid 1px gray;
  margin-left: 10px;
  margin-right: 5px;
}

.TopBar_box___XiAw {
  display: flex;
  flex-direction: row;
  width: 45%;
  height: 100%;
  align-items: center;
  justify-content: end;
  padding: 0px 5px;
  margin-left: auto;
}

.TopBar_searchBox__Mwzhe {
  display: flex;
  flex-direction: row;
  height: 95%;
  width: 40%;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  border: solid 1px gray;
  border-radius: 15px;
  padding: 0px 8px;
}


/* .searchBox {
  position: fixed;
  top: 21%;
  left: 69%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  height: 35px;
  width: 20%;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  border: solid 1px gray;
  border-radius: 15px;
  padding: 0px 8px;
  z-index: 999;
}  */

.TopBar_searchText__1bUwQ {
  border: solid 0px;
  outline: none;
  width: 90%;
}

.TopBar_addBox__3SX89 {
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  margin: 10px;
}

.TopBar_arrowBox__yYjjR {
  display: flex;
  width: 6%;
  height: 70%;
  border: solid 1px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  z-index: 3;
  margin: 10px;
}

.TopBar_arrowBox__yYjjR:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.TopBar_owner__mIRzj {
  /* margin: 0px;
  font-size: 15px;
  font-weight: bold; */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 2px 10px;
  /* height: 95%; */
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  /* margin: 10px; */
}

.TopBar_innerChild__2GZwl {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.TopBar_icon__OnFWW {
  cursor: pointer;
}

.TopBar_columnManage__V_IfM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.TopBar_tooltiptext__qL-4B {
  visibility: hidden;
  background-color: #272944;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  margin-top: 70px;
  margin-right: 25px;

  /* Position the tooltip */
  position: absolute;
  z-index: 3;
}

.TopBar_columnManage__V_IfM:hover .TopBar_tooltiptext__qL-4B {
  visibility: visible;
}

.TopBar_refreshIcon__1F_nU {
  display: flex;
  background-color: #272944;
  padding: 7px 9px;
  border-radius: 5px;
}

.TopBar_leadContainer__1LU_K {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 5px;
  margin-left: auto;
}

.TopBar_leadBox__2A_V2 {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
}

.TopBar_leadText__2prxw {
  margin: 0px;
  color: #272944;
  font-size: 13px;
}

.TopBar_checkbox__2VbVR{
  margin-top: -0.75rem;
}
.Import_parent__2KXf- {
  position: absolute;
  min-width: 140px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 10px 10px; */
  padding: 0.5rem;
  background-color: white;
  margin-right: 40px;
  z-index: 10;
  top: 0;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5rem;
}

.Import_importText__228J9 {
  /* appearance: none;
  text-decoration: none; */
  cursor: pointer;
  /* margin: 0px;
  color: #272944;
  background-color: white;
  border: solid 0px;
  outline: none;
  text-align: center; */
}

.Import_line__2_yLS {
  display: flex;
  border: solid 1px lightgray;
  width: 100%;
}

.Import_showAnim__21FOu {
  -webkit-animation: Import_showImport__1sVv4 0.15s ease-out;
          animation: Import_showImport__1sVv4 0.15s ease-out;
}

@-webkit-keyframes Import_showImport__1sVv4 {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 60px;
    opacity: 1;
  }
}

@keyframes Import_showImport__1sVv4 {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 60px;
    opacity: 1;
  }
}

.DistributionModel_parent__2EFdk {
  margin-top: 2rem;
  width: 60vh;
  min-width: 400px;
  /* height: 88vh; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 450px;
  max-height: 450px;
  display: flex;
  flex-direction: column;

  background-color: white;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px; */
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5rem;
  padding: 1rem;
}

.DistributionModel_parent__2EFdk::-webkit-scrollbar {
  width: 6px;

  height: 5px;
}

.DistributionModel_parent__2EFdk::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.DistributionModel_parent__2EFdk::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.DistributionModel_parent__2EFdk::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.DistributionModel_overlay__3USsq {
  /* height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  z-index: 1000;
}

.DistributionModel_firstDiv__2kYia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 25px; */
  box-sizing: border-box;
}
.DistributionModel_cross__1vQic {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DistributionModel_contactForm__2nYR_ {
  margin: 0px;
  /* font-size: 25px; */
  /* font-weight: 600; */
}

.DistributionModel_line__26i1F {
  display: flex;
  margin-top: 15px;
  border: solid 1px #dcdcdc80;
}

.DistributionModel_text__3BxnD {
  margin: 0px;
  margin-top: 25px;
  /* font-size: 15px; */
  /* font-weight: 600; */
}

.DistributionModel_dropDown__butwE {
  margin-top: 20px;
}

.DistributionModel_apply__2Mpek {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100px;
  padding: 0.5rem 0rem;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background-color: #272944;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  border-radius: 0.25rem;
}
.DistributionModel_apply1__3hr0J {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 50%;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  background-color: #272944;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  border-radius: 0.25rem;
}

.DistributionModel_title__2c2G- {
  display: flex;
  flex-direction: row;
  /* padding-left: 10px; */
  margin: 0px;
  margin-top: 3%;
}

.DistributionModel_one__3Nz5P {
  /* font-size: 15px; */
  /* font-weight: bold; */
  margin: 0px;
}

.DistributionModel_two__3lt9b {
  /* font-size: 15px; */
  /* font-weight: bold; */
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.DistributionModel_box2__1RVvJ {
  display: flex;

  flex-direction: row;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between;
}

.DistributionModel_divide__6tjdb {
  display: flex;
  flex-direction: row;  
  width: 100%;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}
.DistributionModel_button__gbpX5 {
  background-color:#272944;
  /* font-size: 18px; */
  font-weight: bold;
  color: white;
  margin-top: 10%;
  border: solid 0px;
  outline: none;
  width: 50%;
  align-self: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px;
}

.DistributionModel_buttonsBox__1636A {
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-top: 2.5rem;
  /* align-items: flex-end; */
  justify-content: space-around;
}
.DistributionModel_buttonsBox1__kI5kU {
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-top: 4rem;
  align-items: center;
  justify-content: center;
}


.DistributionModel_delete__1COox {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100px;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: #e32636;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  /* margin-right: 10px; */
  border-radius: 0.25rem;
}

.Dropdown_select__RUm-l input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.Dropdown_select__RUm-l{
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}

.Analytics_parent__UemUc {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 900px;
  height: 91.5vh;
  /* background-color: #363853; */
  /* margin-top: 8px; */
}

.Analytics_navContainer__dIcPN {
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 100%;
  /* background-color: #272944; */
  padding: 20px 10px;
  box-sizing: border-box;
}

.Analytics_analyticsText__1Mxar {
  margin: 0px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.Analytics_navigationContainer__3mN4z {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.Analytics_navigateText__2TEGr {
  margin: 0px;
  color: white;
  font-size: 12px;
  margin-left: 10px;
}

.Analytics_container__1IY5Y {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: white !important; */
  padding: 20px 30px;
  box-sizing: border-box;
  font-family: "Poppins" !important;
}

.Analytics_topContainer__1Rlql {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 20%;
  /* margin-top: 10px; */
}

.Analytics_type__3tsSI {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

.Analytics_panel__RJXBI {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: -22px;
  background-color: white;
  height: 55px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}

.Analytics_contactsButton__3SqPO {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: white;
  padding: 7px 20px;
  color: black;
  border-width: 0px;
  border-radius: 2px;
  font-weight: 600;

  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
}

.Analytics_tasksButton__1s_iR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: white;
  padding: 7px 20px;
  color: black;
  border-width: 0px;
  border-radius: 2px;
  font-weight: 600;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
}

.Analytics_callsButton__3Xoen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: white;
  padding: 7px 20px;
  color: black;
  border-width: 0px;
  border-radius: 2px;
  font-weight: 600;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
}

.Analytics_associate__30w3G {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 20px;
  background-color: cadetblue;
  color: white;
  border-width: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.Analytics_source__Npv-5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 25px;
  background-color: cadetblue;
  color: white;
  border-width: 0px;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.Analytics_filters__3lPDh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-left: auto;
}

.Analytics_graphs__3jm_6 {
  display: flex;
  flex-direction: column;
  width: 100%;

  height: 80%;
  padding-bottom: 25px;
  overflow-y: auto;
}

.Analytics_countContainer__3LtVw {
  display: flex;
  flex-direction: column;
}

.Analytics_contHeading__ZEVCB {
  margin: 0px;
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.Analytics_teamLeadHeading__2blY4 {
  margin: 0px;
  font-size: 13px;
  font-family: "Poppins" !important;
}

.Analytics_countText__f7CbM {
  margin: 0px;
  font-size: 25px;
  font-weight: bolder;
  margin-top: 5px;
  font-family: "Poppins" !important;
}

.Analytics_filterBox__3015l {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: solid 1px #00000090;
  outline: none;
  padding: 3px 10px;
  color: black;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 5px;
}

.Analytics_filterBox2__3d56w {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  /* background-color: #272944; */
  /* color: white; */
  background-color: white;
  border: solid 1px #00000090;
  outline: none;
  padding: 3px 10px;
  color: black;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 5px;
}

.Analytics_leadCountContainer__1rxYG {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.Analytics_countBox__-9e9f {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100px;
  height: 55px;
  padding: 5px 5px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
.Analytics_countBox1__1FT5A {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100px;
  height: 55px;
  padding: 5px 5px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}

.Analytics_leadCount__3kuCe {
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.Analytics_leadText__2XYnY {
  margin: 0px;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

.Analytics_bottomDiv__Gr70J {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: auto;
}

.Analytics_clear__n-ROB {
  display: flex;
  flex-direction: row;
  background-color: white;
  color: #272944;
  border-width: 0px;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.Analytics_line__3dlC6 {
  display: flex;
  flex-direction: row;
  width: 95%;
  border: solid;
  border-color: #272944;
  border-width: 0.9px;
  align-self: center;
}

.Analytics_topBar__3Ui7I {
  min-width: 900px;
  width: 100%;
  /* background-color: white !important; */
  display: flex;
  /* margin: 0px auto; */
  /* padding: 8px 0px; */
  align-self: center;
  /* margin-top: 7px; */
}

.Analytics_graphs__3jm_6::-webkit-scrollbar {
  width: 6px;

  height: 5px;
}

.Analytics_graphs__3jm_6::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.Analytics_graphs__3jm_6::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.Analytics_graphs__3jm_6::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Analytics_filter__ga0h7 {
  flex-direction: column;
  display: flex;
  position: relative;
  margin-left: auto;
}

a:link {
  text-decoration: none;
}

/* visited link */
a:visited {
  text-decoration: none;
}

/* mouse over link */
a:hover {
  text-decoration: none;
}

/* selected link */
a:active {
  text-decoration: none;
}

.TableHeader_th__33i6h {
  /* background-color: #484b49;
  color: white; */
  margin: 0px;
  font-size: 14px;
  border: solid 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
 
  padding: 0 50px;
  display: flex;
  justify-content: flex-start;
  max-width: 150px;
  min-width: 150px;
  box-sizing: border-box;
}
.TableHeader_thSmall__10nO2 {
  /* background-color: #484b49;
  color: white; */
  margin: 0px;
  font-size: 14px;
  border: solid 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  padding: 0 50px;
  display: flex;
  justify-content: flex-start;
  max-width: 200px;
  min-width: 100px;
  box-sizing: border-box;
}

.TableHeader_tHeadContainer__dMile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: #e9ecef; */
}

.TableHeader_tooltip__1LglI {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.TableHeader_tooltip__1LglI .TableHeader_tooltiptext__1x6ok {
  visibility: hidden;
  width: 50px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.TableHeader_tooltip__1LglI:hover .TableHeader_tooltiptext__1x6ok {
  visibility: visible;
}

.TableHeader_snoDiv__2zU85 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.TableHeader_sticky__Q_Z4u {
  /* background-color: #484b49; */
   /* background-color: #e9ecef; */
  /* color: white; */
  font-size: 14px;
  min-width: 90px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  /* border-right: solid 0.3px lightgray;
  border-left: solid 0.3px lightgray; */
  z-index: 2;
}

.TableHeader_icon__27Aoi {
  /* padding: 5px 10px; */
  cursor: pointer;
}

.TableHeader_iconOpen__1orS9 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation: TableHeader_rotateIconOpen__1w4Pk 0.2s ease-out;
          animation: TableHeader_rotateIconOpen__1w4Pk 0.2s ease-out;
}

.TableHeader_iconClose__2__ud {
  -webkit-animation: TableHeader_rotateIconClose__UPlJs 0.2s ease-out;
          animation: TableHeader_rotateIconClose__UPlJs 0.2s ease-out;
}

.TableHeader_expandFilter__3DjtT {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
  padding-right: 10px;
}

@-webkit-keyframes TableHeader_rotateIconOpen__1w4Pk {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@keyframes TableHeader_rotateIconOpen__1w4Pk {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@-webkit-keyframes TableHeader_rotateIconClose__UPlJs {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes TableHeader_rotateIconClose__UPlJs {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.FilterModal_parent__eQWk5 {
  position: absolute;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 5px;
  padding-bottom: 10px; */
  padding: 1rem 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  top: 0;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 10px;
}

.FilterModal_child__2IDSs {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  /* padding-left: 10px; */
  /* margin-top: 10px; */
  cursor: pointer;
}

.FilterModal_text__2w7Xd {
  /* color: gray; */
  margin: 0px;
  margin-left: 5px;
  font-size: 12px;
}

.FilterModal_line__1aZma {
  display: flex;
  margin-top: 10px;

  width: 100%;
  height: 1px;
  border: solid 1px;
  background-color: lightgrey;
}

.FilterModal_searchBox__1boVc {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  border: solid 1px lightgrey;
  align-items: center;
  justify-content: space-between;
  /* padding: 4px 6px 4px 6px; */
  /* border-radius: 5px; */
  padding:0.25rem;
  border-radius: 0.25rem;
  background-color: #fff;
}

.FilterModal_searchText__1XGRM {
  color: gray;
  outline: none;
  border: solid 0px;
  width: 80%;
  font-size: 12px;
}

.FilterModal_resultBox__3guBI {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 180px;
  border: solid 1px lightgrey;
  border-radius: 0.25rem;
  margin-top: 5px;
}

.FilterModal_dateInputContainer__3axOi {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 160px;
  border: solid 1px lightgrey;
  margin-top: 10px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
}
.FilterModal_dateInput__2DyB2{
  border-radius: 0.25rem;
}

.FilterModal_checkView__1LzdS {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FilterModal_optionText__1Mq1- {
  /* color: gray; */
  margin: 0px;
  /* font-weight: 500; */
  font-size: 12px;
}

.FilterModal_buttonBox__3yCID {
  display: flex;
  margin-top: 0.5rem;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.FilterModal_clearButton__IAkG0 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #272944;
  border: solid 1px #272944;
  outline: none;
  /* border-radius:20px; */
  border-radius: 0.5rem;
  margin-right: 5px;
  /* padding: 3px 6px 3px 6px; */
  padding: 0.25rem 0.5rem;
}

.FilterModal_applyButton__3UgFa {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #272944;
  color: white;
  border: solid 1px #272944;
  outline: none;
  /* border-radius: 20px; */
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  /* padding: 4px 20px 4px 20px; */
}

.FilterModal_showFilterAnim__3N7J1 {
  -webkit-animation: FilterModal_showFilter__1_K-q 0.2s ease-out;
          animation: FilterModal_showFilter__1_K-q 0.2s ease-out;
}

.FilterModal_infiniteScroll__2zV5A::-webkit-scrollbar {
  width: 6px;

  height: 6px;
}

.FilterModal_infiniteScroll__2zV5A::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.FilterModal_infiniteScroll__2zV5A::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.FilterModal_infiniteScroll__2zV5A::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@-webkit-keyframes FilterModal_showFilter__1_K-q {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 350px;
    opacity: 1;
  }
}

@keyframes FilterModal_showFilter__1_K-q {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 350px;
    opacity: 1;
  }
}

.ColumnManager_parent__3-I64 {
  display: flex;
  flex-direction: column;
  width: 30vw;
  min-width: 250px;
  background-color: white;
  height: 520px;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.ColumnManager_overlay__DsIYn {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.ColumnManager_headContainer__2D2UI {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ColumnManager_searchBox__1QYNt {
  display: flex;
  flex-direction: row;
  border: solid 1.5px #272944;
  background-color: #fff;
  border-radius: 15px;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  margin-top: 0.5rem;
}

.ColumnManager_searchText__2s4X2 {
  display: flex;
  border: solid 0px;
  outline: none;
}

.ColumnManager_manageText__1SYNr {
  font-weight: 500;
  /* color: #272944;
  font-size: 17px; */
}

.ColumnManager_listContainer__sA9M- {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 5px;
  margin-top: 15px;

  height: 350px;
  overflow-y: auto;
}

.ColumnManager_text__7PLzo {
  margin: 0px;
  margin-left: 5px;
  /* font-size: 14px; */
}

.ColumnManager_checkbox__Ct4_m {
  margin-top: 5px;
  accent-color: #272944 !important;
}

.ColumnManager_child__3EXfk {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.ColumnManager_buttonContainer__3r8gi {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.ColumnManager_saveButton__2uq3j {
  display: flex;
  align-items: center;
  background-color: #272944;;
  border: solid 0px;
  outline: none;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
}

.ColumnManager_cancelButton__3HJJZ {
  display: flex;
  align-items: center;
  background-color: #d51e1e;
  border: solid 0px;
  outline: none;
  color: white;
  padding: 5px 13px;
  border-radius: 5px;
}

.ColumnManager_resetButton__2sXeH {
  display: flex;
  align-items: center;
  background-color: #0a6085;
  border: solid 0px;
  outline: none;
  color: white;
  padding: 5px 13px;
  border-radius: 5px;
}

.ColumnManager_showColumnAnim__3pCLN {
  -webkit-animation: ColumnManager_showColumnManager__3Vt-1 0.2s ease-in;
          animation: ColumnManager_showColumnManager__3Vt-1 0.2s ease-in;
  margin-top: 65px;
  border-radius: 10px;
}

@-webkit-keyframes ColumnManager_showColumnManager__3Vt-1 {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 500px;
    opacity: 1;
  }
}

@keyframes ColumnManager_showColumnManager__3Vt-1 {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 500px;
    opacity: 1;
  }
}

.PasswordChange_parent__fT_EP {
  margin-top: 2rem;
  width: 60vh;
  min-width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5rem;
  padding: 1rem;
}

.PasswordChange_parent__fT_EP::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.PasswordChange_parent__fT_EP::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.PasswordChange_parent__fT_EP::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.PasswordChange_parent__fT_EP::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.PasswordChange_overlay__2uu9f {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  z-index: 1000;
}

.PasswordChange_profileContainer__1MU_V {
  display: flex;
  flex-direction: column;
}

.PasswordChange_profile__35gd5 {
  margin: 0;
  margin-top: 10px;
  font-size: 14px;
  font-family: "Poppins" !important;
}

.PasswordChange_inputField__3XB1e {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 10px;
  border-radius: 8px;
}

.PasswordChange_buttonView__3WUk2 {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.PasswordChange_cancelButton__1cKti,
.PasswordChange_saveButton__3Wkwi {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.PasswordChange_cancelButton__1cKti {
  background-color: #ccc;
  background-color: red;
  color: white;
}
.PasswordChange_saveButton__3Wkwi {
  background-color: #272944;
  color: white;
}
.PasswordChange_passwordInputContainer__2kK5-{
  border-radius: 5px !important;
}

.AddOrganization_parent__3xWjv {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  min-width: 900px;
  width: 100vw;
  min-height: 100vh;
  /* min-height: 400px;
  height: 148vh; */
  /* padding: 1.5rem 4rem; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.AddOrganization_child__k1uBh {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* margin-left: 1%; */
  height: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
  /* padding-left: 2%; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* padding-right: 2%; */
}

.AddOrganization_heading__2aaRn {
  /* font-size: 20px; */
  font-weight: bold;
  color: #272944;
  margin-top: 10px;
  margin-left: 10px;
}

.AddOrganization_title__1gyVA {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
  margin-top: 3%;
}

.AddOrganization_one__BnVp5 {
  /* font-size: 15px; */
  font-weight: bold;
  margin: 0px;
}

.AddOrganization_two__3m5Lf {
  /* font-size: 15px; */
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.AddOrganization_select__2Cxnu input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.AddOrganization_select__2Cxnu{
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}


.AddOrganization_box2__3tQ5j {
  display: flex;

  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between; */
  grid-gap: 1rem;
  gap: 1rem;
  padding-right: 1rem !important;
  justify-content: space-between; 
}

.AddOrganization_divide__3uFPs {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.AddOrganization_inputContainer__2eu5i{
  width: 150px;
}

.AddOrganization_button__pFzDy {
  background-color: #272944;
  /* font-size: 18px; */
  /* font-weight: bold; */
  color: white;
  margin-top: 2rem;
  border: solid 0px;
  outline: none;
  width: 25%;
  align-self: center;
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px; */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.AddOrganization_headerView__oyOWi {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.AddOrganization_closeIcon__oE-og {
  margin-left: auto;
  cursor: pointer;
}

.AddOrganization_closeIcon__oE-og:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.TextInput_input__ggbKk {
  display: flex;
  flex-direction: row;
  /* background-color: whitesmoke; */
  /* border: solid 1px; */
  align-items: center;
  /* padding: 14px 10px 14px 7px;
  border-color: whitesmoke; */

  /* margin-top: 3%; */
  /* border-radius: 5px; */
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
}

.TextInput_inputText__3IOln {
  border: solid 0px;
  background-color: whitesmoke;
  padding-left: 10px;
  font-size: 13px;
  outline: none;
  width: 90%;
}

.TextInput_error__10tQm {
  margin: 0px;
  text-align: right;
  color: red;
  margin-top: 8px;
  font-size: 12px;
  margin-right: 10px;
}

/* .css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
    margin-top: 50px !important;
    color: black !important;
}

.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
    width: calc(64px + 1px);
    margin-top: 50px !important;
}
.css-heg063-MuiTabs-flexContainer {
    background-color: #f3f3f3 !important;
}

.css-1ujnqem-MuiTabs-root {
    min-height: none !important;

}
.css-10hburv-MuiTypography-root {
  font-family: "none","none","none",none !important;
}

.MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root.Navbar_button__14mxG.Navbar_active__2pjWC.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root {
  background-color: #279f9f !important;
  color: white !important;
}
.css-10hburv-MuiTypography-root {
  font-family: "Poppins"!important;
}
.css-wa5fuu-MuiListItemIcon-root {
  margin-right: 8px !important;
} */
/* .container .Mui-box{
  margin-top: 50px !important;
} */
.containerSideBar .MuiDrawer-paper{
  /* margin-top: 100px; */
  margin-top: auto;
}
.containerSideBar .MuiListItemIcon-root{
  margin-right: 10px !important;
  /* margin-top: 20px; */
}

.containerSideBar .css-10hburv-MuiTypography-root {
  font-family: "Poppins"!important;
  font-weight: bold !important;
}
.containerSideBar .MuiListItemText-primary
{
  font-family: "Poppins"!important;
  font-weight: bold !important;
  font-size: 0.8rem !important;
}
.side-container{
    background: rgb(0, 48, 61);
    width: 220px;
    height: 100vh;
    position: relative;
    color: white;
    transition: 0.4s;
    overflow-y: scroll
}

.side-container-NX {
    width: 90px;
    height: 100vh;
    overflow-x: hidden;
}

.name {
    text-align: center;
}

.nav-upper, nav-heading, nav-menu,
menu-item {
    display: grid;
}

.nav-menu {
    grid-template-rows: repeat(7, 1fr);
    margin-top: 50px;
    font-size: 90%;
}

.nav-heading {
    grid-template-columns: 2fr, 1fr;
    grid-template-rows: 1fr;
    height: 65px;
    /* z-index: 999; */

}
.nav-brand {
    display: flex;
}
.nav-brand img {
    width: 80%;
    height: auto;
    padding: 20px 10px;
}
.hamburger {
    position: absolute;
    right: 25px;
    top: 0;
    background: none;
    border: none;
    cursor:pointer ;
    margin: 0 -35px;
    /* background-color: #fe355f; */
    border: 2px solid white ;
    padding: 20px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    scale: 60%;
}

.hamburger span{
    display: block;
    margin-top: 5px;
    background-color: white;
    border-radius: 15px;
    height: 4px;
    width: 30px;
    transition: 0.4s;
    -webkit-transform: translate(-3px, -3px);
            transform: translate(-3px, -3px);
}

.hamburger-out:hover span:nth-child(1) {
    width: 25px;
    -webkit-transform: translate(-3px, 9px) rotate(30deg);
            transform: translate(-3px, 9px) rotate(30deg);
}
.hamburger-out:hover span:nth-child(2) {
    display: none;
}
.hamburger-out:hover span:nth-child(3) {
    width: 25px;
    -webkit-transform: translate(-3px, -9px) rotate(-30deg);
            transform: translate(-3px, -9px) rotate(-30deg);
}

.hamburger-in:hover span:nth-child(1) {
    width: 25px;
    -webkit-transform: translate(0px, 9px) rotate(-30deg);
            transform: translate(0px, 9px) rotate(-30deg);
}
.hamburger-in:hover span:nth-child(2) {
    display: none;
}   
.hamburger-in:hover span:nth-child(3) {
    width: 25px;
    -webkit-transform: translate(0px, -9px) rotate( 30deg);
            transform: translate(0px, -9px) rotate( 30deg);
}

.menu-item {
    height: 50px;
    width: 90%;
    display: flex;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    margin: 15px 20px -10px 10px;
    border-radius: 10px;
    position: relative;
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
    font-family: 'Poppins', sans-serif ;
    /* scale: 95%%; */
    
}

.menu-item:hover {
	/* background: #fe355f; */
    color: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* overflow-wrap: anywhere; */
}

.menu-item img {
    width: 30px;
    padding: 0;
    /* filter: invert(); */
    margin-left: 10px;
}

.white-img {
    -webkit-filter: invert();
            filter: invert();
}  

.menu-item-NX {
    margin-top: 40;
    /* margin: auto; */
    width: 4.5em;
}

.menu-item:hover > .tooltip_nav {
    display: block;
    /* z-index: 1800; */
}

.menu-item p {
    margin-top: 1rem;
    margin-left: 1.5rem;
    font-weight: 500;
}

/* .tooltip_nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  position: absolute;
  padding: 5px;
  background-color: #fff;
  color: #000;
  left: -35px;
  bottom: -9px;
} */
.tooltip_nav {
    display: none;
    position: absolute;
    left: -35px;
    bottom: -9px;
    width: 100vw;
    height: auto;
    padding: 0px 35px;
    border-radius: 15px 15px 15px 0;
    white-space: nowrap;
    /* word-break: break-all; */
    /* border: 2px solid rgb(26, 43, 60); */
    background-color: white;
    color: black;
    
    /* text-wrap: wrap;  */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* text-align: center !important; */
}

/* .tooltip_nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 1;
} */
  
.light-mode {
    background-color: #fff;
}
.menu-text-with-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
  }
  
  .menu-arrow-icon {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .line {
    display: flex;
    flex-direction: row;
    width: 95%;
    border: solid;
    border-color: white;
    border-width: 0.9px;
    align-self: center;
    margin-top: -10px;
    margin-bottom: 10px;
  }
.AddHeaders_parent__J7dnU {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
    min-width: 900px;
    width: 100vw;
    min-height: 100vh;
    /* min-height: 400px;
    height: 148vh; */
    /* padding: 1.5rem 4rem; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .AddHeaders_child__27QlI {
    display: flex;
    flex-direction: column;
    width: 600px;
    /* margin-left: 1%; */
    height: 100%;
    background-color: #f3f3f3;
    margin-top: 10px;
    /* padding-left: 2%; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
    /* padding-right: 2%; */
  }
  
  .AddHeaders_heading__1yJQL {
    /* font-size: 20px; */
    font-weight: bold;
    color: #272944;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .AddHeaders_title__3vjrk {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    margin: 0px;
    margin-top: 3%;
  }
  
  .AddHeaders_one__3Fr5c {
    /* font-size: 15px; */
    font-weight: bold;
    margin: 0px;
  }
  
  .AddHeaders_two__1ukn5 {
    /* font-size: 15px; */
    font-weight: bold;
    color: red;
    margin: 0px;
    margin-left: 2px;
  }
  
  .AddHeaders_select__3A2In input {
    /* font-family: "Times New Roman"; */
    font-family: "Poppins" !important;
    font-size: 6px;
  }
  .AddHeaders_select__3A2In{
    color: #000;
    max-width: 100%;
    width: 100%;
    margin-left: auto;
  }
  
  
  .AddHeaders_box2__4MPQY {
    display: flex;
  
    flex-direction: row;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    /* align-items: flex-start !important; */
    /* padding-left: 10px;
    padding-right: 20px;
    align-items: center;
    justify-content: space-between; */
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    /* //space-between  */
  }
  .AddHeaders_boxBooking__1KihR {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    /* height: 10px; */
  }
  
  .AddHeaders_divide__1u2hT {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 30%;
  }
  .AddHeaders_inputContainer__35r7g{
    width: 150px;
  }
  
  .AddHeaders_button__3A-BY {
    background-color: #272944;
    /* font-size: 18px; */
    /* font-weight: bold; */
    color: white;
    margin-top: 2rem;
    border: solid 0px;
    outline: none;
    width: 25%;
    align-self: center;
    /* padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px; */
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .AddHeaders_headerView__28yrG {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  
  .AddHeaders_closeIcon__3U_hy {
    margin-left: auto;
    cursor: pointer;
  }
  
  .AddHeaders_closeIcon__3U_hy:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  
.CommonGraphStyles_graphContainer__VNF8q {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  width: 100%;
  height: 380px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  /* justify-content: center; */
}

.CommonGraphStyles_graphBox__LhSs7 {
  display: flex;
  width: 71%;
  padding: 0px 10px;
  height: 100%;
}

.CommonGraphStyles_heading__1jQ8c{
  /* padding: 0px 15px; */
  font-weight: 700;
  margin-bottom: 1rem;
  /* color: #272944; */
}

.CommonGraphStyles_line__JX1bs {
  display: flex;
  height: 100%;
  border: solid 1px lightgray;
}

.CommonGraphStyles_detailsBox__3ogqB {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 15px;
  overflow-x: auto;
  font-family: 'Poppins' !important;
}

.CommonGraphStyles_scrollPart__2IlJ5 {
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.CommonGraphStyles_scrollPart__2IlJ5::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.CommonGraphStyles_scrollPart__2IlJ5::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.CommonGraphStyles_scrollPart__2IlJ5::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.CommonGraphStyles_scrollPart__2IlJ5::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.CommonGraphStyles_detailsBox__3ogqB::-webkit-scrollbar {
  width: 3px;

  height: 3px;
}

.CommonGraphStyles_detailsBox__3ogqB::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.CommonGraphStyles_detailsBox__3ogqB::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.CommonGraphStyles_detailsBox__3ogqB::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.CommonGraphStyles_graphHeading__1EFfk {
  display: flex;
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  /* text-align: center; */
  justify-content: center;
}
.CommonGraphStyles_typeBox__1Chqp {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* align-items: left; */
  justify-content: space-between;
  margin-top: 5px;
  /* padding: 0px 15px; */

  margin-bottom: 10px;
}

.CommonGraphStyles_text__1OHq9 {
  display: flex;
  margin: 0px;
  font-size: 0.8rem;
  font-weight: 630;
  padding-bottom: 10px;
  cursor: pointer;
  /* justify-content:center; */
}
@media only screen and (max-width: 900px) {
  .CommonGraphStyles_text__1OHq9 {
    font-size: 0.5rem;
    /* line-height:2 */
  }
}

.CommonGraphStyles_graphLine__1ctAk {
  display: flex;
  width: 100%;
  border: solid 1px #dcdcdc80;
}

.CommonGraphStyles_chart__2yq5H {
  display: flex;
  height: 10px;
  width: 10px;
}

.CommonGraphStyles_filterBox2__1wrt4 {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: #272944;
  color: white;
  border: solid 0px;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 90px;
}

/* .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: white ! important;
    color: var(--bs-table-striped-color);
} */
.table{
    --bs-table-hover-bg: #C5C5C5 !important;
}
tbody, td, tfoot, th, thead, tr {
    border-style: none !important;
}
/* .caret {
    display: inline-block !important;
    width: 0 !important;
    height: 0 !important;
    margin-left: 2px !important;
    vertical-align: middle !important;
    border-top: 4px dashed !important;
    border-top: 4px solid \9 !important;
    border-right: 4px solid transparent !important;
    border-left: 4px solid transparent !important;
} */
/* .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0 !important;
    border-bottom: 4px dashed !important;
    border-bottom: 4px solid \9 !important;
    content: "" !important;
  } */

/* .react-bootstrap-table .table-bordered {
    border-top: none !important;
  }
  
  .react-bootstrap-table th {
    position: sticky !important;
    top: 0 !important;
    background-color: #fff !important;
    border-top: none !important;
  }
  
  .react-bootstrap-table th::before {
    position: absolute;
    content: '';
    top: 0 !important;
    left: -2px !important;
    width: calc( 100% + 2px);
    height: 1px !important;
    background-color: #dee2e6 !important;
  }
  
  .react-bootstrap-table th::after {
    position: absolute;
    content: '';
    bottom: -2px !important;
    left: -2px !important;
    width: calc( 100% + 2px);
    height: 1px !important;
    background-color: #dee2e6 !important;
  } */
.AnalyticsFilter_parent__2W7RY {
  width: 250px;
  display: flex;
  position: absolute;
  top: 10px;
  right: -28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0.5rem;
  z-index: 20;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  /* font-family: 'Poppins' !important; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.AnalyticsFilter_parentSelect__3ZqE6{
  width: 250px;
  display: flex;
  position: absolute;
  top: 10px;
  right: -28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0.5rem;
  z-index: 20;
  width: 100%;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  /* font-family: 'Poppins' !important; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.AnalyticsFilter_select__IgmNF{
  width: 100%;
}
.AnalyticsFilter_headContainer__1SFS7 {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /* font-family: 'Poppins' !important; */
}

.AnalyticsFilter_heading__2Z-Qb {
  margin: 0px;
  font-size: 0.7rem;
  font-weight: 500;
  /* font-family: 'Poppins'!important; */
}

.AnalyticsFilter_resetText__jpIcE {
  /* margin: 0px;
  font-size: 10px;
  font-weight: 500;
  color: red; */
  cursor: pointer;
}

.AnalyticsFilter_box__Fcj1e {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.AnalyticsFilter_filterText__-Trs0 {
  margin: 0px;
  font-size: 9px;
  font-weight: 500;
}

.AnalyticsFilter_line__2exra {
  display: flex;
  width: 100%;
  border: solid 1px #dcdcdc80;
}

.AnalyticsFilter_customFilterContainer__3NEhu {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.KanbanViewModal_parent__3os17 {
  margin-top: 30px;
  width: 70vh;
  min-width: 800px;
  height: 88vh;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* padding: 15px 10px; */
  padding: 1rem;
  outline: none;
  border-radius: 0.5rem;

  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.KanbanViewModal_filterBox__JO1AH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: solid 1px #00000090;
  outline: none;
  padding: 3px 10px;
  color: black;
  /* font-size: 12px; */
  font-weight: 600;
  border-radius: 5px;
}

.KanbanViewModal_overlay__3QWqu {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.KanbanViewModal_header__2YEeE {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.KanbanViewModal_heading__2cM17 {
  margin: 0px;
  font-size: 25px;
  color: #272944;
}

.KanbanViewModal_body__33-M2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 20px;
}
.KanbanViewModal_tableHeader__1KbiS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}
.KanbanViewModal_tableContent__IGSb4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}

.KanbanViewModal_columnName__2XuCV {
  margin: 0px;
  font-size: 20px;
}

.KanbanViewModal_item__u1BIk {
  margin: 0px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.KanbanViewModal_line__2IrOz {
  display: flex;
  flex-direction: row;
  width: 95%;
  border: solid;
  border-color: lightgray;
  border-width: 0.5px;
  align-self: center;
}

.KanbanViewModal_bottom__SbTkq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2.5rem;
  /* padding: 0px 10px; */
  padding:0 1rem;
  box-sizing: border-box;
}

.KanbanViewModal_save__3am16 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* padding: 8px 20px; */
  background-color: #272944;
  color: white;
  border-width: 0px;
  cursor: pointer;
  /* border-radius: 10px; */
  /* font-size: 15px; */
  padding:0.25rem 0.5rem;
  border-radius:0.25rem;
  min-width: 6rem;
}

.KanbanViewModal_dateInputContainer__2Bg9V {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.KanbanViewModal_dateInput__RsE7L{
  border-radius:0.25rem;
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
}
.KanbanViewModal_multiselect_fields_value__1j1gI {
  min-width: 200px !important;
  max-width: 600px!important;
  border-radius:0.25rem;
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  color: #000 !important;
}
/* .labelContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  overflow-x: scroll;
}

.label {
  display: flex;
  align-items: center;
  margin: 1px;
}

.select {
  margin: 5px;
  width: 100px;
  height: 20px;
} */

.tabbutt .MuiTabs-indicator {
    height: 3px !important;
    background-color: #272944 !important;
}

.tabbutt .MuiButtonBase-root{
    color: black !important;
    font-size: 0.8rem !important;
    padding-bottom: 0px !important;
    font-weight: 600 !important;
    font-family: 'Poppins' !important;
}
.tabbutt .MuiTab-textColorPrimary {
    padding-bottom: 0px ! important;
    font-weight: 600 !important;
    font-family: 'Poppins' !important;
}
/* .tabbutt .MuiTabs-flexContainer{
background-color: #f3f3f3 !important;
} */

.checkBoxteamWise .MuiFormControlLabel-label {
    font-weight: 600 !important;
    line-height: 1.5;
    /* letter-spacing: 0.00938em; */
    font-size: 0.8rem !important;
    font-family: 'Poppins' !important;
    color: #272944 !important;
}
.AddContacts_parent__2-K_3 {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  min-width: 900px;
  width: 100vw;
  min-height: 100vh;
  /* min-height: 400px;
  height: 148vh; */
  /* padding: 1.5rem 4rem; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.AddContacts_child__2oxLn {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* margin-left: 1%; */
  height: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
  /* padding-left: 2%; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* padding-right: 2%; */
}

.AddContacts_heading__38STK {
  /* font-size: 20px; */
  font-weight: bold;
  color: #272944;
  margin-top: 10px;
  margin-left: 10px;
}

.AddContacts_title__19Qn7 {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
  margin-top: 3%;
}

.AddContacts_one__2tajg {
  /* font-size: 15px; */
  font-weight: bold;
  margin: 0px;
}

.AddContacts_two__1TUvN {
  /* font-size: 15px; */
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.AddContacts_select__3bhP6 input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.AddContacts_select__3bhP6{
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}


.AddContacts_box2__1DkgK {
  display: flex;

  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between; */
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between; 
}

.AddContacts_divide__7Cm8p {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.AddContacts_inputContainer__1O1i9{
  width: 150px;
}

.AddContacts_button__1ES5w {
  /* background-color: #272944; */
  /* font-size: 18px; */
  /* font-weight: bold; */
  color: white;
  margin-top: 2rem;
  border: solid 0px;
  outline: none;
  width: 25%;
  align-self: center;
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px; */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.AddContacts_headerView__2nEaq {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.AddContacts_closeIcon__RAYj5 {
  margin-left: auto;
  cursor: pointer;
}

.AddContacts_closeIcon__RAYj5:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.ImportModal_parent__3SDv3 {
  margin-top: 68px;
  width: 75vw;
  min-width: 950px;
  height: 80vh;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 10px; */
  margin-left: 250px;
  background-color: white;
  /* border-radius: 10px; */
  border: solid 1px #00000090;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px; */
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ImportModal_parentP__2miML {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 900px;
  height: 91.5vh;
  /* background-color: #363853; */
  /* margin-top: 8px; */
}

.ImportModal_contactForm__2bgXA {
  margin: 0px;
  /* font-size: 30px; */
  color: #272944;
}

.ImportModal_child__XIXNL {
  display: flex;
  flex-direction: column;
  width: 75vw;
  height: 70vh;
  align-items: center;
}

.ImportModal_image__O1MMJ {
  display: flex;
  margin-top: 50px;
}

.ImportModal_dragBox__1c_DU {
  display: flex;
  flex-direction: column;
  border: solid 1px;
  width: 80%;
  background-color: white;
  margin-top: 50px;
  padding: 50px 30px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* font-size: 23px; */
  color: gray;
}

.ImportModal_selectBox__3D-kO {
  width: 20vw;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

.ImportModal_select__2CXon {
  display: none;
}

.ImportModal_uploadButton__xhCKt {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  /* padding: 5px 11px; */
  padding: 0.5rem;
  cursor: pointer;
  /* border-radius: 5px; */
  border-radius: 0.25rem;
  /* font-size: 19px; */
  /* font-weight: 500; */
}

.ImportModal_uploadButton__xhCKt:active {
  opacity: 0.5;
}

.ImportModal_options__15bER {
  display: flex;
  padding: 5px 2px 5px 2px;
  outline: none;
}

.ImportModal_tableBox__1ovXS {
  width: 100%;
  height: 80vh;
  white-space: nowrap;
  overflow-x: auto;
}

.ImportModal_table__75y7a {
  width: 100%;
  margin-top: 1%;
  border-spacing: 0px;
}

.ImportModal_check__20_HF {
  margin-left: 10px;
  padding: 2px;
}

.ImportModal_head__3Q94B {
  padding-left: 10px;
}

.ImportModal_th__k2JMh {
  align-items: center;
  /* justify-content: center; */
  background-color: #484b49;
  color: white;
  margin: 0px;
  /* font-size: 14px; */
  border: solid 0px;
  padding: 10px;
  /* width: 100px; */
}

.ImportModal_no__3WVew {
  background-color: white;
  border-bottom: 5pt solid #f3f3f3;
}

.ImportModal_td__11TXK {
  height: 5vh;
  /* text-align: center; */
  /* background-color: white; */
  border-bottom: 5pt solid #f3f3f3;
  /* padding-right: 20px;
  padding-left: 20px; */
  padding: 20px 10px;
  /* width: 100px; */
}

.ImportModal_disabled__OlGrh {
  background-color: grey;
}

.ImportModal_tHeadContainer__1ZaCD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 30px;
}

.ImportModal_drop__3AHnv {
  display: flex;
  margin-top: -8px;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}
.ImportModal_icon__2Fapq {
  margin-left: 10px;
  margin-top: 3px;
}

.ImportModal_cross__3AIln {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImportModal_buttonBox__2eWyI {
  display: flex;
  width: 95%;
  justify-content: flex-end;
  margin-top: auto;
}

.ImportModal_confirm__2debh {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  /* font-size: 16px; */
  color: white;
  /* padding: 10px 15px 10px 15px;
  border: solid 0px;
  border-radius: 10px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  border: none;
  outline: none;
  min-width: 5rem;
}

.ImportModal_cancel__362pz {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  /* font-size: 16px; */
  color: white;
  border: none;
  outline: none;
  /* padding: 10px 15px 10px 15px;
  border: solid 0px;
  border-radius: 10px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  outline: none;
  margin-right: 2rem;
  min-width: 5rem;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.ImportModal_button__LsepO {
  margin-top: 20%;
  width: 10%;
}

.ImportModal_imageContainer__1f8jK {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  /* display: flex; */
  grid-gap: 1rem;
  gap: 1rem;
  /* flex-wrap: wrap; */
  padding:0.5rem;
  max-height: 75%;
  overflow-y: auto;
  border: solid lightgrey;
  /* padding-bottom: 20px;
  border-radius: 10px; */
  border-radius: 1rem;
  /* margin-top: 10px;*/
  border-width: 1px; 
  margin-top: 1rem;
  min-width: 100%;
}

.ImportModal_imageBox__yW7Bv {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.ListViewModal_overlay__3J0nc {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.ListViewModal_parent__2QdMM {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 55vh;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
}
.ListViewModal_parentApi__1O60s {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 20vh;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
}

.ListViewModal_headingContainer__1f_vY {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ListViewModal_heading__1AGOs {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
}

.ListViewModal_profileContainer__-lkeJ {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.ListViewModal_profile__LLPyt {
  margin: 0px;
  font-size: 18px;
  margin-bottom: 10px;
}

.ListViewModal_box__34rc3 {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-top: 8px;
  justify-content: space-between;
}

.ListViewModal_first__1OU4a {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.ListViewModal_detail__3SCW- {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 5px;
}

.ListViewModal_option__1gps2 {
  margin: 0px;
  font-size: 13px;
}

.ListViewModal_buttonView__2mZTV {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 95%;
}

.ListViewModal_saveButton__3HS2G {
  margin-left: 15px;
  padding: 5px 35px;
  background-color: #272944;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.ListViewModal_cancelButton__tCTKk {
  margin-left: auto;
  padding: 5px 35px;
  background-color: red;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.BranchModal_overlay__HtzmT {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.BranchModal_parent__33f6w {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 55vh;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
}

.BranchModal_headingContainer__1MjTi {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.BranchModal_heading__3ZxGq {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
  margin-bottom: 15px;
}

.BranchModal_detail__1a7Vv {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 5px;
  padding-left: 10px;
  box-sizing: border-box;
}

.BranchModal_option__3CgOT {
  margin: 0px;
  margin-left: 5px;
  font-size: 13px;
}

.BranchModal_buttonView__bV87A {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 95%;
}

.BranchModal_saveButton__2lDhq {
  margin-left: 15px;
  padding: 5px 35px;
  background-color: #272944;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.BranchModal_cancelButton__3QTeU {
  margin-left: auto;
  padding: 5px 35px;
  background-color: red;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.ChangeStageModal_overlay__2MdJw {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.ChangeStageModal_parent__P0cJk {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 55vh;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
}

.ChangeStageModal_headingContainer__laztV {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ChangeStageModal_heading__1Wgxm {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
}

.ChangeStageModal_buttonView__1xs71 {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 95%;
}

.ChangeStageModal_saveButton__3VM1t {
  margin-left: 15px;
  padding: 5px 35px;
  background-color: #272944;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.ChangeStageModal_cancelButton__2uvdS {
  margin-left: auto;
  padding: 5px 35px;
  background-color: red;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.DeleteRecordsModal_overlay__2h9o3 {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.DeleteRecordsModal_parent__3U-Eq {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 55vh;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
}

.DeleteRecordsModal_headingContainer__2vGH8 {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.DeleteRecordsModal_heading__3xOrm {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
}

.DeleteRecordsModal_buttonView__2esN9 {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 95%;
}

.DeleteRecordsModal_saveButton__jY7MC {
  margin-left: 15px;
  padding: 5px 35px;
  background-color: #272944;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.DeleteRecordsModal_cancelButton__2sZLD {
  margin-left: auto;
  padding: 5px 35px;
  background-color: red;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.AddUsers_parent__1QFSe {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  min-width: 900px;
  width: 100vw;
  min-height: 100vh;
  /* min-height: 400px;
  height: 148vh; */
  /* padding: 1.5rem 4rem; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.AddUsers_child__3WrX8 {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* margin-left: 1%; */
  height: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
  /* padding-left: 2%; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* padding-right: 2%; */
}

.AddUsers_heading__1ziGh {
  /* font-size: 20px; */
  font-weight: bold;
  color: #272944;
  margin-top: 10px;
  margin-left: 10px;
}

.AddUsers_title__17SDo {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
  margin-top: 3%;
}

.AddUsers_one__3M9Dr {
  /* font-size: 15px; */
  font-weight: bold;
  margin: 0px;
}

.AddUsers_two__2_1Wh {
  /* font-size: 15px; */
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.AddUsers_select__2vOAz input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.AddUsers_select__2vOAz{
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}


.AddUsers_box2__2oDgi {
  display: flex;

  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between; */
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between; 
}

.AddUsers_divide__3lE2X {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.AddUsers_inputContainer__27HIW{
  width: 200px;
}

.AddUsers_button__3eU2o {
  background-color: #272944;
  /* font-size: 18px; */
  /* font-weight: bold; */
  color: white;
  margin-top: 2rem;
  border: solid 0px;
  outline: none;
  width: 25%;
  align-self: center;
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px; */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

/* .headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
} */

.AddUsers_closeIcon__2Cd55 {
  margin-left: auto;
  cursor: pointer;
}

.AddUsers_closeIcon__2Cd55:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.AddModal_parent__23KcG {
  display: flex;
  flex-direction: column;
  width: 40vw;
  background-color: white;
  height: 26vh;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 13px;
  /* align-items: center; */
  /* justify-content: center; */
}
.AddModal_overlay__3fErF {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.AddModal_icon__3qU4N {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddModal_heading__1t9zs {
  margin-top: -15px;
  font-size: 20px;
  font-weight: bold;
  color: #272944;
}

.AddModal_nameBox__16-Ci {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  margin-top: 4vh;
}

.AddModal_name__1lR1E {
  font-size: 16px;
  font-weight: bold;
}

.AddModal_input__25BZ8 {
  margin-left: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 3px;
  outline: none;
  width: 17vw;
}

.AddModal_button__d3DcQ {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #272944;
  border: solid 0px;
  border-color:#272944;
  outline: none;
  margin-left: auto;
  margin-top: auto;
  padding: 10px 30px 10px 30px;
  color: white;
}

.FAQPanel_parent__1BsDN {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  /* height: 90%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  min-width: 900px;
}

.FAQPanel_child__BoMUu {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 98%;
  height: 99%;
  /* background-color: white !important; */
  margin-top: 20px;

  padding: 0px 10px;
  white-space: nowrap;

  box-sizing: border-box;
}

.FAQPanel_topBar__zTorT {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;

  box-sizing: border-box;
}

.FAQPanel_title__3F0Ha {
  margin: 0px;
  color: #272944;
  font-size: 22px;
}

.FAQPanel_addButton__1UeVr {
  /* display: flex; */
  background-color: #272944;
  color: white;
  /* padding: 7px 25px; */
  outline: none;
  border: solid 0px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
}

.FAQPanel_FAQContainer__3qCo4 {
  display: flex;
  flex-direction: column;
  border: solid 0.5px;
  width: 900px;
  height: 200px;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
}

.FAQPanel_container__2l00b {
  white-space: pre-wrap;
  margin-top: 5px;
  overflow-y: auto;
  padding: 5px 5px;
}

.FAQPanel_heading__1VQ1O {
  margin: 0px;
  font-size: 17px;
  color: #272944;
}

.FAQPanel_line__1ugVd {
  display: flex;
  width: 100%;
  border: solid 1px lightgray;
  margin-bottom: 10px;
  margin-top: 10px;
}

.FAQPanel_text__2vSva {
  margin: 0px;
}

.FAQPanel_container__2l00b::-webkit-scrollbar {
  width: 4px;

  height: 3px;
}

.FAQPanel_container__2l00b::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.FAQPanel_container__2l00b::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.FAQPanel_container__2l00b::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.FAQPanel_subChild__3mom6 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;

  box-sizing: border-box;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;
}

.FAQPanel_subChild__3mom6::-webkit-scrollbar {
  width: 8px;

  height: 3px;
}

.FAQPanel_subChild__3mom6::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.FAQPanel_subChild__3mom6::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.FAQPanel_subChild__3mom6::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.FAQPanel_headerContainer__2mxMd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DeleteSelectedModal_overlay__3tXFz {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 4;
  }
  .DeleteSelectedModal_parentP__3LbYJ {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 900px;
    height: 91.5vh;
    /* background-color: #363853; */
    /* margin-top: 8px; */
  }
  .DeleteSelectedModal_parent__3shqB {
    margin-top: 200px;
  
    /* width: 35vw; */
    /* min-width: 450px;
    height: 55vh; */
    min-width: 320px;
    max-width: 320px;
    min-height: 200px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 10px; */
    background-color: white;
    /* padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px; */
    outline: none;
    overflow-y: auto;
    overflow-x: auto;
    padding: 1rem;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 0.5rem;
  }
  
  .DeleteSelectedModal_headingContainer__2HAt1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .DeleteSelectedModal_optionHeading__3kh_m {
    
    /* color:#272944; */
    /* font-size: 16px; */
  }
  .DeleteSelectedModal_subheading__2HzHr{
    display: flex;
    width: 100%;
  }
  .DeleteSelectedModal_container__3ER8V{
      /* width: 80%;
      height: 80%; */
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
  }
  .DeleteSelectedModal_container__3ER8V >ol>li{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .DeleteSelectedModal_heading__3GqwN {
    /* margin: 0px;
    font-size: 23px; */
    /* color: #272944; */
    /* font-weight: 500; */
  }
  
  .DeleteSelectedModal_buttonView__tH70l {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .DeleteSelectedModal_saveButton__2Zw_9 {
    /* margin-left: 15px; */
    /* padding: 5px 35px; */
    padding: 0.5rem;
    background-color: red;
    color: white;
    outline: none;
    border: 0px;
    border-radius: 0.25rem;
    min-width: 5rem;
  }
  
  .DeleteSelectedModal_cancelButton__3BNEH {
    /* margin-left: auto; */
    /* padding: 5px 35px; */
    padding: 0.5rem;
    background-color: #272944;
    color: white;
    outline: none;
    border: 0px;
    border-radius: 0.25rem;
    min-width: 5rem;
  }
  
.Resources_parent__1XOB9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 900px;
}

.Resources_child__3hBk9 {
  display: flex;
  flex-direction: row;
  min-width: 900px;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  padding: 0px 10px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
}

.Resources_carousel__FPXv4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 96%;
  height: 50%;
  background-color: white;
  padding: 0px 0px;
  overflow-y: auto;
}

.Resources_leadContainer__28RAQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 96%;
  height: 48%;
  background-color: white;
  padding: 0px 0px;
  overflow-y: auto;
  margin-top: 8px;
}

.Resources_location__26Fsi {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin-left: auto;
  height: 70%;
  padding-bottom: 7px;
  justify-content: space-between;
}

.Resources_locationContainer__3iPRf {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 7px;
  /* background-color: white !important; */
  /* border: 1px solid #808080; */
  /* border-radius: 5px; */
}

.Resources_tableContainer__2hHHE {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  overflow-y: auto;
}

.Resources_addLocationContainer__3W7-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding-top: 1rem;
  /* margin-top: 20px; */
}
.Resources_budgetContainer__3EytS {
  display: flex;
  flex-direction: column;
  width: 96%;
  height: 65%;
  padding-bottom: 7px;
  background-color: white;
  margin-top: 20px;
}

.Resources_imageTable__3VGM_ {
  display: flex;
  flex-direction: column;
  height: 450px;
  overflow-y: auto;
  width: 100%;
}

.Resources_table__1dUcB {
  border-spacing: 0px;
  width: 100%;
}

.Resources_countContainer__37Q4p {
  margin-top: auto;
  display: flex;
  width: 96%;
  flex-direction: row;
  background-color: #484b49;
  padding: 5px 12px;
}

.Resources_count__fVte3 {
  margin: 0px;
  color: white;
}

.Resources_head__10HmI {
  background-color: #484b49;
  width: 100%;
}

.Resources_th__1SC5c {
  /* align-items: center;
  justify-content: center; */
  color: white;
  margin: 0px;
  /* font-size: 14px; */
  text-align: center;
  border: solid 0px;
  padding: 5px 15px;
}

.Resources_td___hSGE {
  height: 5vh;
  text-align: center;
  background-color: white;
  border-bottom: 5pt solid #f3f3f3;
  padding-right: 10px;
  padding-left: 30px;
  padding-top: 5px;
}

.Resources_addImageButton__mgP9A {
  display: flex;
  align-items: center;
  margin: "auto";
  width: 50%;
  justify-content: center;
  background-color: #272944;
  color: white;
  border: solid 0px;
  /* padding: 5px 10px;
  border-radius: 5px; */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.Resources_addButton__2JwTd {
  display: flex;
  justify-content:center;
  align-items: center;
  margin:auto;
  width: 50%;
  border: solid 0px;
  background-color: #272944;
  color: white;
  /* border-radius: 5px;
  padding: 5px 0px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.Resources_addButto1__3TwCK {
  display: flex;
  justify-content:center;
  align-items: center;
  /* margin:auto; */
  width: 50%;
  border: solid 0px;
  background-color: #272944;
  color: white;
  /* border-radius: 5px;
  padding: 5px 0px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.Resources_tableContainer__2hHHE::-webkit-scrollbar {
  width: 6px;

  height: 5px;
}

.Resources_tableContainer__2hHHE::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.Resources_tableContainer__2hHHE::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.Resources_tableContainer__2hHHE::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Resources_imageTable__3VGM_::-webkit-scrollbar {
  width: 6px;

  height: 5px;
}

.Resources_imageTable__3VGM_::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.Resources_imageTable__3VGM_::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.Resources_imageTable__3VGM_::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.AddResourceModal_parent__cVvFa {
  display: flex;
  flex-direction: column;
  width: 500px;
  /* background-color: white; */
  height: 220px;
  border: solid 0px;
  /* border-color: white; */
  outline: none;
  /* padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 13px; */
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  /* border: 1px solid #ccc;
  background: #fff; */
  overflow: auto;
  /* border-radius: 4px;
  outline: none;
  padding: 20px; */
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.AddResourceModal_overlay__nRYcJ {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.AddResourceModal_icon__I4V-B {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddResourceModal_heading__141r8 {
  margin: 0px;
  margin-top: -15px;
  font-size: 20px;
  font-weight: bold;
  color: #272944;
}

.AddResourceModal_nameBox__Zf72W {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 15px; */
  margin-top: 0.5rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.AddResourceModal_name__3yNkH {
  font-size: 16px;
  font-weight: bold;
}

.AddResourceModal_input__1YqjW {
  /* padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px; */
  padding: 0.5rem;
  outline: none;
  border: none;
flex: 1 1;
  border-radius: 0.25rem;
}

.AddResourceModal_button__Wj23p {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}

.AddResourceModal_cancel__LeJN8 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 7px 15px 7px 15px; */
  padding: 0.5rem;
  background-color: white;
  border: solid 2px #272944;
  outline: none;
  /* margin-right: 1rem; */
  color: #272944;
  font-size: 14px;
  border-radius: 0.25rem;
  min-width: 100px;
}

.AddResourceModal_change__3nNsk {
  display: flex;
  justify-content: center;
  /* padding: 8px 15px 7px 15px; */
  background-color: #272944;
  outline: none;
  color: white;
  font-size: 14px;
  min-width: 100px;
  border: solid 0px;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
.AddResourceModal_headingContainer__2iEbh{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ImportModal_parent__3gtEm {
  margin-top: 68px;
  width: 75vw;
  min-width: 950px;
  height: 80vh;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 10px; */
  margin-left: 250px;
  background-color: white;
  /* border-radius: 10px; */
  border: solid 1px #00000090;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px; */
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ImportModal_contactForm__2apUT {
  margin: 0px;
  /* font-size: 30px; */
  color: #272944;
}

.ImportModal_child__wEkk_ {
  display: flex;
  flex-direction: column;
  width: 75vw;
  height: 70vh;
  align-items: center;
}

.ImportModal_image__33-dK {
  display: flex;
  margin-top: 50px;
}

.ImportModal_dragBox__35tFZ {
  display: flex;
  flex-direction: column;
  border: solid 1px;
  width: 80%;
  background-color: white;
  margin-top: 50px;
  padding: 50px 30px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* font-size: 23px; */
  color: gray;
}

.ImportModal_selectBox__3Pyi8 {
  width: 20vw;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

.ImportModal_select__2ZE6L {
  display: none;
}

.ImportModal_uploadButton__1udDD {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  /* padding: 5px 11px; */
  padding: 0.5rem;
  cursor: pointer;
  /* border-radius: 5px; */
  border-radius: 0.25rem;
  width: 38%;
  display: flex;
  justify-content: center;
  text-align: center;
  /* font-size: 19px; */
  /* font-weight: 500; */
}

.ImportModal_uploadButton__1udDD:active {
  opacity: 0.5;
}

.ImportModal_options__1Hi6R {
  display: flex;
  padding: 5px 2px 5px 2px;
  outline: none;
}

.ImportModal_tableBox__25fEc {
  width: 100%;
  height: 80vh;
  white-space: nowrap;
  overflow-x: auto;
}

.ImportModal_table__3LlIw {
  width: 100%;
  margin-top: 1%;
  border-spacing: 0px;
}

.ImportModal_check__1dEYv {
  margin-left: 10px;
  padding: 2px;
}

.ImportModal_head__2Zk3Q {
  padding-left: 10px;
}

.ImportModal_th__1cAJr {
  align-items: center;
  /* justify-content: center; */
  background-color: #484b49;
  color: white;
  margin: 0px;
  /* font-size: 14px; */
  border: solid 0px;
  padding: 10px;
  /* width: 100px; */
}

.ImportModal_no__nXTgO {
  background-color: white;
  border-bottom: 5pt solid #f3f3f3;
}

.ImportModal_td__1Ddyk {
  height: 5vh;
  /* text-align: center; */
  /* background-color: white; */
  border-bottom: 5pt solid #f3f3f3;
  /* padding-right: 20px;
  padding-left: 20px; */
  padding: 20px 10px;
  /* width: 100px; */
}

.ImportModal_disabled__1m7dK {
  background-color: grey;
}

.ImportModal_tHeadContainer__1ezrS {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 30px;
}

.ImportModal_drop__3qP-3 {
  display: flex;
  margin-top: -8px;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}
.ImportModal_icon__2Nnrg {
  margin-left: 10px;
  margin-top: 3px;
}

.ImportModal_cross__3FHlp {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImportModal_buttonBox__2hfRa {
  display: flex;
  width: 95%;
  justify-content: flex-end;
  margin-top: auto;
}

.ImportModal_confirm__2U8m6 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  /* font-size: 16px; */
  color: white;
  /* padding: 10px 15px 10px 15px;
  border: solid 0px;
  border-radius: 10px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  border: none;
  outline: none;
  min-width: 5rem;
}

.ImportModal_cancel__3LlCA {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  /* font-size: 16px; */
  color: white;
  border: none;
  outline: none;
  /* padding: 10px 15px 10px 15px;
  border: solid 0px;
  border-radius: 10px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  outline: none;
  margin-right: 2rem;
  min-width: 5rem;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.ImportModal_button__2CIXR {
  margin-top: 20%;
  width: 10%;
}

.ImportModal_imageContainer__3RgDy {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  /* display: flex; */
  grid-gap: 1rem;
  gap: 1rem;
  /* flex-wrap: wrap; */
  padding:0.5rem;
  max-height: 75%;
  overflow-y: auto;
  border: solid lightgrey;
  /* padding-bottom: 20px;
  border-radius: 10px; */
  border-radius: 1rem;
  /* margin-top: 10px;*/
  border-width: 1px; 
  margin-top: 1rem;
  min-width: 100%;
}

.ImportModal_imageBox__3griJ {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.Integration_parent__xtiro {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  min-width: 900px;
  width: 100vw;
  min-height: 100vh;
  /* min-height: 400px;
  height: 148vh; */
  /* padding: 1.5rem 4rem; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Integration_child__79MQf {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* margin-left: 1%; */
  height: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
  /* padding-left: 2%; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* padding-right: 2%; */
}

.Integration_heading__1kPFe {
  /* font-size: 20px; */
  font-weight: bold;
  color: #272944;
  margin-top: 10px;
  margin-left: 10px;
}

.Integration_title__3xnIJ {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
  margin-top: 3%;
}

.Integration_one__2EZr5 {
  /* font-size: 15px; */
  font-weight: bold;
  margin: 0px;
}

.Integration_two__3X9CV {
  /* font-size: 15px; */
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.Integration_select__2sSjI input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.Integration_select__2sSjI {
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}

.Integration_box2__2YxJw {
  display: flex;

  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between; */
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
}

.Integration_divide__3CcPR {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.Integration_inputContainer__b4wB9 {
  width: 150px;
}

.Integration_button__1A4rT {
  background-color: #272944;
  /* font-size: 18px; */
  /* font-weight: bold; */
  color: white;
  margin-top: 2rem;
  border: solid 0px;
  outline: none;
  width: 25%;
  align-self: center;
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px; */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.Integration_headerView__-VQzM {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.Integration_closeIcon__20a1H {
  margin-left: auto;
  cursor: pointer;
}

.Integration_closeIcon__20a1H:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* Add your desired background color and font size here */
.Integration_customfacebooklogin__1EqvR {
  background-color: #3b5998; /* Facebook blue color */
  color: #fff; /* Text color for the button */
  font-size: 1px; /* Adjust font size as needed */
  padding: 1px; /* Add padding to make the button more visually appealing */
  border-radius: 5px; /* Add rounded corners to the button */
  cursor: pointer; /* Show pointer cursor on hover */
  /* Add any other custom styles you want */
}

/* Optional: To center the login button on the page */
.Integration_facebooklogincontainer__1oxrq {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.Integration_circularimage__21bGS {
  width: 50px; /* Set the desired width of the circular image */
  height: 50px; /* Set the desired height of the circular image */
  border-radius: 50%; /* Make the border-radius 50% to create a circular shape */
  overflow: hidden; /* Hide any parts of the image that go beyond the circular shape */
}

.Integration_buttoncancel__1VlC3 {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: rgb(39, 41, 68);
  color: white;
  border: solid 0px;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 2px;
  width: 55px;
  height: 25px;
  font-size: 12px;
  margin: 0px 10px 0px 10px;
  /* margin-top: -0.5rem; */
}
.Integration_customselect__1Jmz0 {
  width: 99%;
  /* Add any other styles you want to apply to the select element */
}

button svg {
  width: 1em; /* Adjust the size of the SVG icon */
  height: auto;
  margin-right: 0.5rem;
}

.Integration_accordionContainer__3A1s6 {
  margin-top: 10px;
  background-color: "#f9f9f9";
  padding: "10px";
}
.TrailPeriodAlert_parent__3xaMj {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 900px;
    /* margin-top: 8px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    min-height: 91.5vh;
  }
  
  .TrailPeriodAlert_container__3k3AP {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: white !important; */
    padding: 20px 30px;
    box-sizing: border-box;
    font-family: "Poppins" !important;
    margin-bottom: 100px;
  }
  
  .TrailPeriodAlert_historyLink__3Fe5b {
    cursor: pointer;
    color: #272944;
  }
  
  .TrailPeriodAlert_button__1Kl8v {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    background-color: #272944;
    color: white;
    border: solid 0px;
    outline: none;
    /* font-size: 0.7rem; */
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 2px;
    width: 100px;
    height: 25px;
    font-size: 12px;
    margin: 0px 10px 0px 10px;
    /* margin-top: -0.5rem; */
  }
  
  .TrailPeriodAlert_buttonSecondary__ZYyWg {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    background-color: #fff;
    color: black;
    border: solid 2px #272944;
    outline: none;
    /* font-size: 0.7rem; */
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 0.5rem;
    width: 100px;
  }
  
.SubscriptionAlert_parent__1CvA0 {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 900px;
    /* margin-top: 8px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    min-height: 91.5vh;
  }
  
  .SubscriptionAlert_container__2ivd7 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: white !important; */
    padding: 20px 30px;
    box-sizing: border-box;
    font-family: "Poppins" !important;
    margin-bottom: 100px;
  }
  
  .SubscriptionAlert_historyLink__1Bu55 {
    cursor: pointer;
    color: #272944;
  }
  
  .SubscriptionAlert_button__2HEbB {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    background-color: #272944;
    color: white;
    border: solid 0px;
    outline: none;
    /* font-size: 0.7rem; */
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 0.5rem;
    width: 100px;
    margin-top: -0.5rem;
  }
  
  .SubscriptionAlert_buttonSecondary__CW2sQ {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    background-color: #fff;
    color: black;
    border: solid 2px #272944;
    outline: none;
    /* font-size: 0.7rem; */
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 0.5rem;
    width: 100px;
  }
  
.booking_container .MuiInputLabel-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.8rem !important;
    font-family: "Poppins" !important;
    /* height: "0.4375em" !important; */
}

.booking_container .MuiFormHelperText-contained {
    font-family: "Poppins" !important;
    /* height: "0.4375em" !important; */
}

.booking_container .MuiOutlinedInput-input {
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
}

.booking_container .MuiInputBase-input {
    height: "0.4375em" !important;
}

.booking_container .MuiDropzoneArea-root {
    min-height: 0px !important;
    margin-top: 15px;
    border: 1px dashed #A9A9A9 !important;
    margin-bottom: 15px;
}


.dropzone-text {
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    font-weight: 700 !important;
    color: #787777 !important;
}

.MuiDropzoneArea-icon {
    width: 25px !important;
    height: 25px !important;
    color: #A9A9A9 !important;
}


.imagePreview {
    height: none !important;
    max-width: 420px !important;
}
.css-1e2bxag-MuiDataGrid-root {
    font-family: "Poppins" !important;
}

.booking-table .MuiDataGrid-columnHeaders {
    min-height: 45px !important;
    max-height: 45px !important;
    line-height: 57px !important;
}

.box {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 7%;
    justify-content: end;
    padding: 0px 5px;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 20px;
}

.addBox {
    display: flex;
    flex-direction: row;
    background-color: #272944;
    color: white;
    font-weight: bold;
    padding: 2px 10px;
    height: 95%;
    align-items: center;
    justify-content: space-evenly;
    border: solid 0px;
    border-radius: 5px;
    outline: none;
}

.MuiDataGrid-colCellTitle {
    display: block;
    text-align: center;
    width: 100%;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
}

.child-container {
    border: 1px solid rgb(174, 174, 174);
    box-shadow: rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
    border-radius: 10px;
    padding: 20px;
    /* margin: 10px; */
    /* margin-top: 20px; */
    margin: 10px !important;
}

.textTypography {
    /* margin-bottom: 20px !important; */
    font-weight: 600 !important;
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin-top: 10px !important;
}

.Mui-disabled {
    font-weight: 600 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: rgb(76, 204, 71) !important;
}

.PaymentDetails_parent__3A757 {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 900px;
  /* margin-top: 8px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  min-height: 91.5vh;
}

.PaymentDetails_select__3OrLS input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.PaymentDetails_select__3OrLS{
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}


.PaymentDetails_container__RbWA4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: white !important; */
  padding: 20px 30px;
  box-sizing: border-box;
  font-family: "Poppins" !important;
  margin-bottom: 100px;
}

.PaymentDetails_historyLink__3CXwH {
  cursor: pointer;
  color: #272944;
}

.PaymentDetails_button__2B1ET {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: #272944;
  color: white;
  border: solid 0px;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100px;
}

.PaymentDetails_buttonSecondary__5zxcy {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: #fff;
  color: black;
  border: solid 2px #272944;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100px;
}

.UpgradeUser_parent__2gOBo {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 900px;
  /* margin-top: 8px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  min-height: 91.5vh;
}

.UpgradeUser_container__1c_c3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: white !important; */
  padding: 20px 30px;
  box-sizing: border-box;
  font-family: "Poppins" !important;
  margin-bottom: 100px;
}

.UpgradeUser_historyLink__24KMV {
  cursor: pointer;
  color: #272944;
}

.UpgradeUser_button__3d8Vf {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: #272944;
  color: white;
  border: solid 0px;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100px;
}

.UpgradeUser_buttonSecondary__20FaR {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: #fff;
  color: black;
  border: solid 2px #272944;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100px;
}

.SubscriptionDetails_parent__2BPaD {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 900px;
  /* margin-top: 8px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 91.5vh;
  position: relative;
}

.SubscriptionDetails_container__RNE0k {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: white !important; */
  padding: 20px 30px;
  box-sizing: border-box;
  font-family: "Poppins" !important;
  margin-bottom: 100px;
}

.SubscriptionDetails_historyLink__30LgE {
  cursor: pointer;
  color: #272944;
}

.SubscriptionDetails_button__37apS {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  background-color: #272944;
  color: white;
  border: solid 0px;
  outline: none;
  /* font-size: 0.7rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 200px;
}

.ProjectsPanel_parent__9xEdM {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100%;
  min-width: 900px;
}

.ProjectsPanel_child__2AH-6 {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 98%;
  height: 87%;
  background-color: white !important;
  margin-top: 0%;
  padding-bottom: 2%;
  border: solid 2px black;
  border-radius: 10px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
}

.ProjectsPanel_topBar__3xJcr {
  min-width: 900px;
  width: 97%;
  background-color: #f3f3f3;
  display: flex;
  margin: 0px auto;
  padding: 8px 5px;
  align-self: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ProjectsPanel_table__1_j2n {
  width: 200%;

  border-spacing: 0px;
}

.ProjectsPanel_td__6tQTm {
  display: flex;
  height: 42px;
  min-width: 250px;
  background-color: white;
  border-bottom: 5px solid #f3f3f3;
  align-items: center;
  padding: 0px 50px;
  justify-content: flex-start;
  max-width: 250px;
  overflow: auto;

  box-sizing: border-box;
}

.ProjectsPanel_sticky__3_rje {
  display: flex;
  background-color: white;
  position: sticky;
  height: 42px;
  left: 0;
  position: -webkit-sticky;
  border-bottom: 5pt solid #f3f3f3;
  min-width: 90px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-right: solid 0.3px lightgray;
}

.ProjectsPanel_td__6tQTm::-webkit-scrollbar {
  display: none;
}

.ProjectsPanel_button__OBUhR {
  margin-top: 20%;
  width: 10%;
}

.ProjectsPanel_count__Zh_D2 {
  display: flex;
  flex-direction: row;
  width: 97%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5%;
}

.ProjectsPanel_countHead__1VV2r {
  margin: 0px;
  font-size: 1rem;
  font-weight: bold;
}

.ProjectsPanel_number__WHHIs {
  margin: 0px;
  font-size: 1rem;
  font-weight: bold;
  color: #272944;
  margin-left: 10px;
}

.StatusFilter_parent__2UeZr {
  position: absolute;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 5px;

  padding-bottom: 15px; */
  padding: 0.5rem 0.25rem;
  background-color: white;
  z-index: 10;
  top: 0;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5rem;
}

.StatusFilter_child__2LIai {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
}

.StatusFilter_text__260b7 {
  margin: 0px;
  margin-top: 5px;
  /* font-size: 14px; */
  /* color: black; */
  cursor: pointer;
}

.StatusFilter_line__2LGEV {
  display: flex;
  width: 90%;
  border: solid 1px lightgrey;
  margin-top: 10px;
  margin-bottom: 5px;
}

.StatusFilter_text__260b7:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.StatusFilter_showFilterAnim__1BBOp {
  -webkit-animation: StatusFilter_showFilter__2uE-w 0.15s ease-out;
          animation: StatusFilter_showFilter__2uE-w 0.15s ease-out;
}

@-webkit-keyframes StatusFilter_showFilter__2uE-w {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 100px;
    opacity: 1;
  }
}

@keyframes StatusFilter_showFilter__2uE-w {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 100px;
    opacity: 1;
  }
}

.AddProject_parent__2Yd6p {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  width: 100vw;
  min-height: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* padding: 1.5rem 4rem; */
}

.AddProject_child__Od43Z {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-left: 1%;
  height: 100%;
  background-color: white;
  margin-top: 10px;
  padding-left: 2%;
  padding-right: 2%;
}

.AddProject_heading__1m_OJ {
  font-size: 20px;
  font-weight: bold;
  color: #272944;
  margin-top: 10px;
  margin-left: 10px;
}

.AddProject_title__1pLXZ {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
}

.AddProject_one__3wlaQ {
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
}

.AddProject_two__2GHBp {
  font-size: 15px;
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.AddProject_box__25vf7 {
  padding-left: 10px;
  padding-right: 20px;
  margin-top: 0px;
}

.AddProject_box2__2o9lF {
  display: flex;

  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* padding-left: 10px;
  padding-right: 20px; */
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.AddProject_divide__1AyEg {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 32%;
}

.AddProject_button__1RDyv {
  background-color: #272944;
  /* font-size: 18px; */
  /* font-weight: bold; */
  color: white;
  margin-top: 2rem;
  border: solid 0px;
  outline: none;
  width: 25%;
  align-self: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px; */
}

.AddProject_headerView__90yyR {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.AddProject_closeIcon__pZYXK {
  margin-left: auto;
  cursor: pointer;
}

.AddProject_closeIcon__pZYXK:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.ProjectDetails_parent__3IFUa {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 92vh; */
  /* min-height: 100vh;
  height: fit-content;
  width: 100vw;
  min-width: 900px; */
  width: 100vw;
  height: 100%;
  min-width: 900px;
  margin-bottom: 20;
}

.ProjectDetails_child__2JEDS {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 900px;
  width: 100%;
  height: 100%;
  /* background-color: white !important; */
  margin-top: 0%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 5px;
  padding: 2rem 4rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.ProjectDetails_documentContainer__3_i9l {
  display: flex;
  flex-direction: column;
  width: 30%;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  /* background-color: white; */
  /* border-radius: 15px;
  height: 100%;
  padding: 20px 20px;
  box-sizing: border-box; */
}

.ProjectDetails_heading__2Z6UN {
  font-size: 18px;
  color: #272944;
  font-weight: 700;
  margin: 0px;
}

.ProjectDetails_addContainer__2qIXY {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ProjectDetails_title__mmFit {
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
}

.ProjectDetails_document__2hNqj {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}

.ProjectDetails_name__1qC1S {
  margin: 0px;
  font-size: 12px;
  margin-left: 5px;
  color: gray;
}

.ProjectDetails_allDocuments__cUZqL {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  overflow-y: auto;
}

.ProjectDetails_allDocuments__cUZqL::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.ProjectDetails_allDocuments__cUZqL::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.ProjectDetails_allDocuments__cUZqL::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.ProjectDetails_allDocuments__cUZqL::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ProjectDetails_projectDetails__h8Ext {
  display: flex;
  flex-direction: column;
  width: 60%;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.ProjectDetails_detailsHeading__839Vh {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 20px;
}

.ProjectDetails_data__2gvjp {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* background-color: white;
  border-bottom: 3px solid #f3f3f3; */
  padding: 0.5rem;
  /* border: 1px solid red; */
  box-sizing: border-box;
}

.ProjectDetails_head__2Lb3u {
  margin: 0px;
  /* border: 1px solid blue; */
  /* font-size: 12px; */
}

.ProjectDetails_text__3gmhP {
  margin: 0px;
  /* width: 80%;
  height: auto; */
  /* font-size: 12px; */
  font-weight: 500;
  /* border: 1px solid black; */
  /* align-items: start;
  overflow-wrap: anywhere; */
  /* wordWrap: "break-word";
   maxWidth: "200px"  */
   /* word-wrap: break-word;
   max-width: 200px; */
}

.ProjectDetails_uploadContainer__7_53C {
  display: flex;
  justify-content: center;
  /* flex-direction: row-reverse; */
 margin-top: 2rem;
  width: 100%;
}
.ProjectDetails_selectBox__1NaeH {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.ProjectDetails_select__3iNMq {
  display: none;
}

.ProjectDetails_uploadButton__1RhWJ {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 500;
}
.ProjectDetails_uploadButton__1RhWJ:hover{
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.25s linear;
}
/* .uploadButton:active {
  opacity: 0.5;
} */
.ProjectDetails_docContainer__2O8MD{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0rem 1rem;
  border-radius: 0.5rem;
}
.ProjectDetails_projectDataContainer__2TZ3W{
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ProjectDocumentModal_parent__V4qZx {
  display: flex;
  flex-direction: column;
  width: 45vw;
  background-color: white;
  height: 50vh;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding: 25px 25px;
  box-sizing: border-box;
}
.ProjectDocumentModal_overlay__29Utr {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.ProjectDocumentModal_title__3bjbv {
  margin: 0px;
  font-size: 19px;
  font-weight: 600;
}

.ProjectDocumentModal_dragBox__-KfIQ {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  /* background-color: #f3f3f3; */
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}
.ProjectDocumentModal_buttonContainer__2q9XV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.ProjectDocumentModal_cancelButton__3_ZHw {
  display: flex;
  background-color: red;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 14px;
  outline: none;
  margin-left: auto;
  /* margin-right: 15px; */
  border: solid 0px;
}
.ProjectDocumentModal_selectBox__RU-tY {
  display: flex;

  justify-content: center;
}

.ProjectDocumentModal_select__2UxI4 {
  display: none;
}

.ProjectDocumentModal_uploadButton__3smLf {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-left: 1rem;
}

.ProjectDocumentModal_uploadButton__3smLf:active {
  opacity: 0.5;
}

.ProjectVideoModal_overlay__EgKiR {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 4;
  }
  .ProjectVideoModal_dragBox__1A5mN {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60%;
    background-color: #f3f3f3;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .ProjectVideoModal_parent__k8z79 {
    margin-top: 130px;
  
    width: 35vw;
    min-width: 450px;
    height: 50vh;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 10px; */
    background-color: white;
    /* padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px; */
    outline: none;
    overflow-y: auto;
    overflow-x: auto;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .ProjectVideoModal_headingContainer__3Vli2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .ProjectVideoModal_heading__3fiO1 {
    margin: 0px;
    font-size: 23px;
    color: #272944;
    font-weight: 500;
  }
  
  .ProjectVideoModal_detailContainer__25DlT {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .ProjectVideoModal_image__3xtLB {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    cursor: pointer;
    background-size: contain;
  }
  .ProjectVideoModal_imageStyle__3IRzi{
    width: 300px;
    height: 200px;
    cursor: pointer;
    background-size: contain;
    margin-bottom:10px;
  }
  
  .ProjectVideoModal_name__36Bbl {
    margin: 0px;
    font-size: 20px;
    margin-top: 5px;
  }
  
  .ProjectVideoModal_uploadContainer__H85z4 {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    align-items: center;
    justify-content: space-evenly;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;
    width: 100%;
  }
  .ProjectVideoModal_selectBox__3GXIv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
  }
  
  .ProjectVideoModal_select__22Wem {
    display: none;
  }
  
  .ProjectVideoModal_uploadButton__Cxg6w {
    display: flex;
    background-color: #272944;
    color: #ffffff;
    /* padding: 7px 15px; */
    cursor: pointer;
    border: solid 0px;
    outline: none;
    /* border-radius: 5px;
    font-size: 19px;
    font-weight: 500; */
    border-radius: 0.25rem;
    padding: 0.5rem 2rem;
  }
  
  .ProjectVideoModal_uploadButton__Cxg6w:active {
    opacity: 0.5;
  }
  
  .ProjectVideoModal_buttonContainer__fgYKm {
    display: flex;
  }
  
  .ProjectVideoModal_button__11uPD {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d51e1e;
    color: white;
    border: solid 0px;
    outline: none;
    /* font-weight: 700; */
    /* padding: 10px 35px; */
    border-radius: 0.25rem;
    padding: 0.35rem 2rem;
    /* border-radius: 5px; */
    font-size: 16px;
  }
  
.FAQPanel_parent__wC9A7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  /* height: 90%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  min-width: 900px;
}

.FAQPanel_child__WrnxY {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 98%;
  height: 99%;
  /* background-color: white !important; */
  margin-top: 20px;

  padding: 0px 10px;
  white-space: nowrap;

  box-sizing: border-box;
}

.FAQPanel_topBar__2CBvZ {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;

  box-sizing: border-box;
}

.FAQPanel_title__1Kit_ {
  margin: 0px;
  color: #272944;
  font-size: 22px;
}

.FAQPanel_addButton__DA3Be {
  /* display: flex; */
  background-color: #272944;
  color: white;
  /* padding: 7px 25px; */
  outline: none;
  border: solid 0px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
}

.FAQPanel_FAQContainer__Md0Af {
  display: flex;
  flex-direction: column;
  border: solid 0.5px;
  width: 900px;
  height: 200px;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
}

.FAQPanel_container__18DoU {
  white-space: pre-wrap;
  margin-top: 5px;
  overflow-y: auto;
  padding: 5px 5px;
}

.FAQPanel_heading__2Z7y3 {
  margin: 0px;
  font-size: 17px;
  color: #272944;
}

.FAQPanel_line__FuGo6 {
  display: flex;
  width: 100%;
  border: solid 1px lightgray;
  margin-bottom: 10px;
  margin-top: 10px;
}

.FAQPanel_text__3_9jq {
  margin: 0px;
}

.FAQPanel_container__18DoU::-webkit-scrollbar {
  width: 4px;

  height: 3px;
}

.FAQPanel_container__18DoU::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.FAQPanel_container__18DoU::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.FAQPanel_container__18DoU::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.FAQPanel_subChild__3gxl7 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;

  box-sizing: border-box;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;
}

.FAQPanel_subChild__3gxl7::-webkit-scrollbar {
  width: 8px;

  height: 3px;
}

.FAQPanel_subChild__3gxl7::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.FAQPanel_subChild__3gxl7::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.FAQPanel_subChild__3gxl7::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.FAQPanel_headerContainer__2Gv5_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ImportModal_parent__1QWfH {
  margin-top: 68px;
  width: 75vw;
  min-width: 950px;
  height: 80vh;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 10px; */
  margin-left: 250px;
  background-color: white;
  /* border-radius: 10px; */
  border: solid 1px #00000090;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px; */
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ImportModal_contactForm__BQLU0 {
  margin: 0px;
  /* font-size: 30px; */
  color: #272944;
}

.ImportModal_child__2Tdco {
  display: flex;
  flex-direction: column;
  width: 75vw;
  height: 70vh;
  align-items: center;
}

.ImportModal_image__JlHdF {
  display: flex;
  margin-top: 50px;
}

.ImportModal_dragBox__1qa31 {
  display: flex;
  flex-direction: column;
  border: solid 1px;
  width: 80%;
  background-color: white;
  margin-top: 50px;
  padding: 50px 30px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* font-size: 23px; */
  color: gray;
}

.ImportModal_selectBox__XEiWP {
  width: 20vw;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

.ImportModal_select__E2Upi {
  display: none;
}

.ImportModal_uploadButton__xvZJm {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  /* padding: 5px 11px; */
  padding: 0.5rem;
  cursor: pointer;
  /* border-radius: 5px; */
  border-radius: 0.25rem;
  /* font-size: 19px; */
  /* font-weight: 500; */
}

.ImportModal_uploadButton__xvZJm:active {
  opacity: 0.5;
}

.ImportModal_options__1t8zv {
  display: flex;
  padding: 5px 2px 5px 2px;
  outline: none;
}

.ImportModal_tableBox__O3Fr6 {
  width: 100%;
  height: 80vh;
  white-space: nowrap;
  overflow-x: auto;
}

.ImportModal_table__3yx_i {
  width: 100%;
  margin-top: 1%;
  border-spacing: 0px;
}

.ImportModal_check__2hlae {
  margin-left: 10px;
  padding: 2px;
}

.ImportModal_head__2djmd {
  padding-left: 10px;
}

.ImportModal_th__WtZHk {
  align-items: center;
  /* justify-content: center; */
  background-color: #484b49;
  color: white;
  margin: 0px;
  /* font-size: 14px; */
  border: solid 0px;
  padding: 10px;
  /* width: 100px; */
}

.ImportModal_no__3W2R_ {
  background-color: white;
  border-bottom: 5pt solid #f3f3f3;
}

.ImportModal_td__tclrO {
  height: 5vh;
  /* text-align: center; */
  /* background-color: white; */
  border-bottom: 5pt solid #f3f3f3;
  /* padding-right: 20px;
  padding-left: 20px; */
  padding: 20px 10px;
  /* width: 100px; */
}

.ImportModal_disabled__1E-H1 {
  background-color: grey;
}

.ImportModal_tHeadContainer__13DP1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 30px;
}

.ImportModal_drop__2gZX2 {
  display: flex;
  margin-top: -8px;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}
.ImportModal_icon__3eTzv {
  margin-left: 10px;
  margin-top: 3px;
}

.ImportModal_cross__1TpDC {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImportModal_buttonBox__2ii0p {
  display: flex;
  width: 95%;
  justify-content: flex-end;
  margin-top: auto;
}

.ImportModal_confirm__34zGp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  /* font-size: 16px; */
  color: white;
  /* padding: 10px 15px 10px 15px;
  border: solid 0px;
  border-radius: 10px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  border: none;
  outline: none;
  min-width: 5rem;
}

.ImportModal_cancel__axdbm {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  /* font-size: 16px; */
  color: white;
  border: none;
  outline: none;
  /* padding: 10px 15px 10px 15px;
  border: solid 0px;
  border-radius: 10px; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  outline: none;
  margin-right: 2rem;
  min-width: 5rem;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.ImportModal_button__2CbjU {
  margin-top: 20%;
  width: 10%;
}

.ImportModal_imageContainer__1sAET {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  /* display: flex; */
  grid-gap: 1rem;
  gap: 1rem;
  /* flex-wrap: wrap; */
  padding:0.5rem;
  max-height: 75%;
  overflow-y: auto;
  border: solid lightgrey;
  /* padding-bottom: 20px;
  border-radius: 10px; */
  border-radius: 1rem;
  /* margin-top: 10px;*/
  border-width: 1px; 
  margin-top: 1rem;
  min-width: 100%;
}

.ImportModal_imageBox__113wN {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.LeadDistributorLogic_parent__2SgH_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  /* height: 90%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  min-width: 900px;
}
.LeadDistributorLogic_child__LS7vZ {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 98%;
  height: 99%;
  /* background-color: white !important; */
  margin-top: 20px;

  padding: 0px 10px;
  white-space: nowrap;

  box-sizing: border-box;
}

.LeadDistributorLogic_topBar__2hfyY {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;

  box-sizing: border-box;
}

.LeadDistributorLogic_title__1esFQ {
  margin: 0px;
  color: #272944;
  font-size: 22px;
}

.LeadDistributorLogic_addButton__2SUmi {
  /* display: flex; */
  background-color: #272944;
  color: white;
  /* padding: 7px 25px; */
  outline: none;
  border: solid 0px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
}

.LeadDistributorLogic_FAQContainer__2xZxt {
  display: flex;
  flex-direction: column;
  border: solid 0.5px;
  width: 900px;
  height: 200px;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
}

.LeadDistributorLogic_container__2C5p9 {
  white-space: pre-wrap;
  margin-top: 5px;
  overflow-y: auto;
  padding: 5px 5px;
}

.LeadDistributorLogic_heading__29hB6 {
  margin: 0px;
  font-size: 17px;
  color: #272944;
}

.LeadDistributorLogic_line__3VbcH {
  display: flex;
  width: 100%;
  border: solid 1px lightgray;
  margin-bottom: 10px;
  margin-top: 10px;
}

.LeadDistributorLogic_text__2uVql {
  margin: 0px;
}

.LeadDistributorLogic_container__2C5p9::-webkit-scrollbar {
  width: 4px;

  height: 3px;
}

.LeadDistributorLogic_container__2C5p9::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.LeadDistributorLogic_container__2C5p9::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.LeadDistributorLogic_container__2C5p9::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.LeadDistributorLogic_subChild__1QWoE {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;

  box-sizing: border-box;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;
}

.LeadDistributorLogic_subChild__1QWoE::-webkit-scrollbar {
  width: 8px;

  height: 3px;
}

.LeadDistributorLogic_subChild__1QWoE::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.LeadDistributorLogic_subChild__1QWoE::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.LeadDistributorLogic_subChild__1QWoE::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.LeadDistributorLogic_headerContainer__1HJY7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.LeadDistributorLogic_firstDiv__1z4Ha {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 25px; */
  box-sizing: border-box;
}
.LeadDistributorLogic_cross__1FJPa {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LeadDistributorLogic_contactForm__1Q7rI {
  margin: 0px;
  /* font-size: 25px; */
  /* font-weight: 600; */
}

.LeadDistributorLogic_line__3VbcH {
  display: flex;
  margin-top: 15px;
  border: solid 1px #dcdcdc80;
}

.LeadDistributorLogic_text__2uVql {
  margin: 0px;
  margin-top: 25px;
  /* font-size: 15px; */
  /* font-weight: 600; */
}

.LeadDistributorLogic_dropDown__3KR85 {
  margin-top: 20px;
}

.LeadDistributorLogic_apply__14LtN {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100px;
  padding: 0.5rem 0rem;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background-color: #272944;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  border-radius: 0.25rem;
}
.LeadDistributorLogic_apply1__337hm {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 17%;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  background-color: #272944;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  border-radius: 0.25rem;
}

.LeadDistributorLogic_title__1esFQ {
  display: flex;
  flex-direction: row;
  /* padding-left: 10px; */
  margin: 0px;
  margin-top: 3%;
}

.LeadDistributorLogic_one__1q8kU {
  /* font-size: 15px; */
  /* font-weight: bold; */
  margin: 0px;
}

.LeadDistributorLogic_two__28bTj {
  /* font-size: 15px; */
  /* font-weight: bold; */
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.LeadDistributorLogic_box2__3QwMD {
  display: flex;

  flex-direction: row;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between;
}

.LeadDistributorLogic_divide__GYoaQ {
  /* display: flex; */
  flex-direction: row;  
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
  grid-gap: 1rem;
  gap: 1rem;
}
.LeadDistributorLogic_button__3PKJv {
  background-color:#272944;
  /* font-size: 18px; */
  font-weight: bold;
  color: white;
  margin-top: 10%;
  border: solid 0px;
  outline: none;
  width: 50%;
  align-self: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px;
}

.LeadDistributorLogic_buttonsBox__DGh87 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2.5rem;
  justify-content: space-around;
  margin-left: 100%;
}
.LeadDistributorLogic_buttonsBox1__1Wwnj {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  /* margin-left: 100%; */
}


.LeadDistributorLogic_delete__1dPXi {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100px;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: #e32636;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  /* margin-right: 10px; */
  border-radius: 0.25rem;
}

.booking_container .MuiInputLabel-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.8rem !important;
    font-family: "Poppins" !important;
    /* height: "0.4375em" !important; */
}

.booking_container .MuiFormHelperText-contained {
    font-family: "Poppins" !important;
    /* height: "0.4375em" !important; */
}

.booking_container .MuiOutlinedInput-input {
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
}

.booking_container .MuiInputBase-input {
    height: "0.4375em" !important;
}

.booking_container .MuiDropzoneArea-root {
    min-height: 0px !important;
    margin-top: 15px;
    border: 1px dashed #A9A9A9 !important;
    margin-bottom: 15px;
}

.dropzone-text {
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    font-weight: 700 !important;
    color: #787777 !important;
}

.MuiDropzoneArea-icon {
    width: 25px !important;
    height: 25px !important;
    color: #A9A9A9 !important;
}


.imagePreview {
    height: none !important;
    max-width: 420px !important;
}
.css-1e2bxag-MuiDataGrid-root {
    font-family: "Poppins" !important;
}

.booking-table .MuiDataGrid-columnHeaders {
    min-height: 45px !important;
    max-height: 45px !important;
    line-height: 57px !important;
}

.box {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 7%;
    justify-content: end;
    padding: 0px 5px;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 20px;
}

.addBox {
    display: flex;
    flex-direction: row;
    background-color: #272944;
    color: white;
    font-weight: bold;
    padding: 2px 10px;
    height: 95%;
    align-items: center;
    justify-content: space-evenly;
    border: solid 0px;
    border-radius: 5px;
    outline: none;
}

.MuiDataGrid-colCellTitle {
    display: block;
    text-align: center;
    width: 100%;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
}

.child-container {
    border: 1px solid rgb(174, 174, 174);
    box-shadow: rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
    border-radius: 10px;
    padding: 20px;
    /* margin: 10px; */
    /* margin-top: 20px; */
    margin: 10px !important;
}

.textTypography {
    /* margin-bottom: 20px !important; */
    font-weight: 600 !important;
    font-family: "Poppins" !important;
    font-size: 0.8rem !important;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin-top: 10px !important;
}

.Mui-disabled {
    font-weight: 600 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: rgb(76, 204, 71) !important;
}
.addBox {
    display: flex;
    flex-direction: row;
    background-color: #AEAEAE !important;
    color: white;
    /* font-size: 13px; */
    font-weight: bold;
    padding: 2px 10px;
    height: 95%;
    align-items: center;
    justify-content: space-evenly;
    border: solid 0px;
    border-radius: 5px;
    outline: none;
  }
  .button_addBox {
    display: flex;
    flex-direction: row;
    background-color: #272944 !important;
    color: white;
    /* font-size: 13px; */
    font-weight: bold;
    padding: 2px 10px;
    height: 95%;
    align-items: center;
    justify-content: space-evenly;
    border: solid 0px;
    border-radius: 5px;
    outline: none;
  }
.HolidayConfiguration_parent__hHeVm {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 900px;
    height: auto;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  button:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: 0.2s ease-in-out;
  }
  
.HolidayConfigModel_overlay__Z3h7P {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.HolidayConfigModel_parent__Gw0a9 {
  margin-top: 130px;
  width: 35vw;
  min-width: 450px;
  height: 40vh;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 10px;
}
.HolidayConfigModel_parentApi__1Zb_w {
  margin-top: 130px;
  width: 35vw;
  min-width: 450px;
  height: 20vh;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
}
.HolidayConfigModel_heading__MJiim {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
}
.HolidayConfigModel_profileContainer__1V9B- {
  display: flex;
  flex-direction: column;
  width: 95%;
}
.HolidayConfigModel_saveButton__1-1zq {
  margin-left: 15px;
  padding: 5px 35px;
  background-color: #272944;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}
.HolidayConfigModel_cancelButton__3RIb6 {
  margin-left: auto;
  padding: 5px 35px;
  background-color: red;
  color: white;
  outline: none;
  border: 0px;
  border-radius: 5px;
}
.HolidayConfigModel_label__1J2Qr{
  margin-bottom: 20;
}
.ChangeOwnerModal_parent__1UnF5 {
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: white;
  height: auto;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 13px;
  margin: auto;
  /* box-shadow: -1px 2px 10px 3px rgba(0, 0, 0, 0.44); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  border-radius: 1rem;
}
.ChangeOwnerModal_overlay__2GpHb {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.ChangeOwnerModal_icon__2jXL_ {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChangeOwnerModal_heading__1Rmq6 {
  margin: 0px;
  /* margin-top: -15px; */
  color:#272944; 
  /* font-size: 20px;
   font-weight: bold; */
}

.ChangeOwnerModal_nameBox__4lJIV {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  /* border: 2px solid black; */
}

.ChangeOwnerModal_options__3kzWV {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.ChangeOwnerModal_optionHeading__2EV4J {
 
  color: #272944;
  font-size: 16px;
}
.ChangeOwnerModal_subheading__E-VYI{
  display: flex;
  width: 100%;
}
.ChangeOwnerModal_optionText__3c62V {
  margin: 0px;
}

.ChangeOwnerModal_name__2VrQg {
  font-size: 15px;
  font-weight: bold;
}
input {
  margin: 0.4rem;
}

label {
  font: 1rem "Fira Sans", sans-serif;
}
.ChangeOwnerModal_inputDiv__33f8M {
  margin-top: 0.6rem;
}

/* .input {
  margin-left: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  width: 17vw;
} */

.ChangeOwnerModal_button__mPF4b {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}

.ChangeOwnerModal_cancel__mRM9X {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 7px 15px 7px 15px; */
  /* padding: 8px 15px 7px 15px; */
  padding: 0.5rem;
  background-color: white;
  border: solid 2px #272944;
  outline: none;
  margin-right: 20px;
  color: #272944;
  font-size: 14px;
  position: absolute;
  right: 80px;
  border-radius: 0.5rem;
}

.ChangeOwnerModal_change__Jx8J_ {
  display: flex;
  /* padding: 8px 15px 7px 15px; */
  padding:0.5rem;
  background-color: #272944;
  outline: none;
  color: white;
  font-size: 14px;
  border: solid 2px;
  position: relative;
  margin-left: 27rem;
  border-radius: 0.5rem;
}

.BulkEditModal_parent__KbjwM {
  margin-top: 30px;
  width: 60vh;
  min-width: 600px;
  height: 88vh;
  min-height: 480px;
  display: flex;
  flex-direction: column;

  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-sizing: border-box;
  border-radius: 1rem;
  padding:1rem;
}

.BulkEditModal_parent__KbjwM::-webkit-scrollbar {
  width: 6px;

  height: 5px;
}

.BulkEditModal_parent__KbjwM::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.BulkEditModal_parent__KbjwM::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.BulkEditModal_parent__KbjwM::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.BulkEditModal_overlay__WdAC2 {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.BulkEditModal_firstDiv__2ZVI8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  box-sizing: border-box;
}
.BulkEditModal_cross__1QR5z {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BulkEditModal_contactForm__2qNav {
  margin: 0px;
  font-size: 25px;
  font-weight: 600;
}

.BulkEditModal_line__G5XZq {
  display: flex;
  margin-top: 15px;
  border: solid 1px #dcdcdc80;
}

.BulkEditModal_text__24OUo {
  margin: 0px;
  margin-top: 1rem;
  /* font-size: 15px;
  font-weight: 600; */
}

.BulkEditModal_dropDown__1gaa1 {
  margin-top: 20px;
}

.BulkEditModal_apply__3y7hN {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100px;
  /* padding: 10px 0px; */
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background-color: #272944;
  color: white;
  outline: none;
  cursor: pointer;
  border: solid 0px;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.BulkEditModal_title__2fYtr {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
  margin-top: 3%;
}

.BulkEditModal_one__1_o__ {
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
}

.BulkEditModal_two__RZpIm {
  font-size: 15px;
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.BulkEditModal_box2__2C88Y {
  display: flex;

  flex-direction: row;
  margin-top: 20px;
  /* padding-left: 10px;
  padding-right: 20px; */
  align-items: center;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.BulkEditModal_divide__1EoPj {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.BulkEditModal_dateInput__3yyn-{
  border-radius:0.25rem;
  padding: 0.25rem 0.5rem;
  outline: none;
  width: 100%;
  /* border: none; */
  border: 1px solid gray;
}

.ContactDetails_parent__2rwNz {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
  /* min-height: 100vh;
  width: 100vw;
  min-width: 900px; */
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 900px;
}

.ContactDetails_child__16VRP {
  display: flex;
  flex-direction: row;
  min-width: 900px;
  width: 100%;
  height: 100%;
  margin-top: 0%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 5px;
  padding: 0 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  padding-bottom: 1rem;
}

.ContactDetails_detailsContainer__222qo {
  display: flex;
  flex-direction: column;
  width: 75%;
  /* margin: 1rem 0 2rem 0; */
}

.ContactDetails_contactDetails__bp0_9 {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 100%; */
  /* padding: 5px 15px; */
  padding: 0rem 0 ;
  border-radius: 0.5rem;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ContactDetails_firstContainer__3C6Xx {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-top: 15px;
  align-items: center;
}

.ContactDetails_nameDiv__1v_61 {
  display: flex;
  flex-direction: column;

  width: 90%;
}

.ContactDetails_nameDiv__1v_61::-webkit-scrollbar {
  display: none;
}

.ContactDetails_name__CWaCu {
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
}
.ContactDetails_customerEmail__2QBAQ {
  font-size: 12px;
  margin: 0px;
  color: #272944;
}

.ContactDetails_buttonContainer__3oQyw {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.ContactDetails_data__SmxvM {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  /* border-bottom: 3px solid #f3f3f3; */
  padding: 0.25rem 0;
}

.ContactDetails_heading__1yiqm {
  margin: 0px;
  /* font-size: 12px; */
}

.ContactDetails_text__3yO01 {
  margin: 0px;
  /* font-size: 12px; */
  font-weight: 500;
}

.ContactDetails_secondContainer__2-WEU {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  padding-top: 10px;
}

.ContactDetails_emailContainer__1DXCf {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 20px;
  margin-bottom: 10px;
}

.ContactDetails_email__2ipme {
  margin: 0px;
  font-size: 14px;
  color: #272944;
  margin-left: auto;
  font-weight: 600;
  margin-top: 5px;
}

.ContactDetails_taskDetails__1t9jL {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  margin-top: 2.5rem;
  box-sizing: border-box;
  /* padding: 0px 5px;
  padding-left: 35px; */
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ContactDetails_tableContainer__1jBre {
  width: 100%;
  height: 100%;
  /* height: 250px; */
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: auto;
}

.ContactDetails_table__2a1X8 {
  width: 100%;
}

.ContactDetails_head__3Qk9k {
  background-color: #272944;
}

.ContactDetails_th__1oJrO {
  /* align-items: center;
  justify-content: center; */
  color: white;
  margin: 0px;
  /* font-size: 12px; */
  font-weight: 400;
  border: solid 0px;
  padding: 0.5rem 1rem;
}

.ContactDetails_td__2vxy6 {
  /* text-align: center; */
  background-color: white;
  border-bottom: 1px solid #f3f3f3;
  padding: 0.5rem 1rem;
  /* font-size: 13px; */
  /* font-weight: 500; */
}

.ContactDetails_task__3ML4t {
  margin: 0px;
  font-size: 15px;
  font-weight:600;
  margin-top: 80px;
}

.ContactDetails_notesContainer__2Bcyt {
  /* display: flex;
  flex-direction: row; */
  width: 25%;
  height: 100%;
  /* border-radius: 0.5rem; */
  /* padding: 1rem; */
  /* align-items: center; */
}

.ContactDetails_childNotes__1L-c8 {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  /* margin-top: 25px; */
  width: 100%;
  height: 100%;
  background-color: white;
  /* border-radius: 10px; */
  box-sizing: border-box;
  /* padding: 25px 10px; */
}

.ContactDetails_notesSection__12C8B {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 30%; */
  /* min-height: 250px; */
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ContactDetails_notesHead__3YNbf {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* padding: 5px 10px; */
}

.ContactDetails_notes__2OzrR {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
}

.ContactDetails_attachmentContainer__iES9V {
  display: flex;
  flex-direction: column;
  height:100px;
  overflow-y: auto;
}

.ContactDetails_container__1oFRA {
  display: flex;
  flex-direction: column;
  white-space: normal;
  height: 100px;
  width: 100%;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

.ContactDetails_attachmentItems__1xSqB {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ContactDetails_noteDisplay__1jbyw {
  display: flex;
  /* width: 97%; */
  /* flex-direction: row; */
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */
  margin-top: 0.5rem;
  border-bottom: 2px solid #f3f3f3;
}

.ContactDetails_box__3OGBT {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  /* max-width: 200px; */
  /* width: 60%; */
  height: 100%;
  width: 100%;
  /* overflow-x: auto; */
}
.ContactDetails_innerBox__18IkQ {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: start; */
  padding: 5px 10px;
}
.ContactDetails_dateAndCreatedByBox__2uQ6G {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ContactDetails_createdBy__14oIM {
  font-size: 12px;
}
.ContactDetails_note__3K3Dl {
  /* margin: 0px; */
  margin: auto;
  /* font-size: 12px; */
  /* font-size: 12px; */
  /* font-weight: 500; */
  /* margin-left: 10px; */
  width: 100%;
}

.ContactDetails_container__1oFRA::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.ContactDetails_container__1oFRA::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.ContactDetails_container__1oFRA::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.ContactDetails_container__1oFRA::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ContactDetails_noContent__rKyIz {
  margin: 0px;
  text-align: center;
  color: #272944;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}

.ContactDetails_box__3OGBT::-webkit-scrollbar {
  display: none;
}

.ContactDetails_date__3HviT {
  margin: 0px;
  font-size: 12px;
  color: #272944;
}

.ContactDetails_attachmentSection__1Wrdh {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 80%; */
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* min-height: 250px; */
  /* margin-top: 10px; */
}

.ContactDetails_ownerButton__W8Wgw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 30%;
  color: white;
  background-color: #272944;
  margin-top: -10px;
  margin-bottom: 5px;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.ContactDetails_dropdownButton__3JS7S {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  width: 100%;
  color: white;
  background-color: #272944;
  margin-top: -10px;
  margin-bottom: 5px;
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.ContactDetails_attachmentBox__3Cr9u {
  display: flex;
  flex-direction: row;
  /* max-width: 300px; */
  width: 100%;
  align-items: center;
  /* overflow-x: auto; */
  /* height: fit-content; */
  /* padding: 9px 5px;
  margin-top: 10px; */
  margin-top: 1rem;
  /* overflow-y: hidden; */
  grid-gap: 1rem;
  gap: 1rem;
  /* border: 1px solid #fff; */
}

.ContactDetails_attachmentBox__3Cr9u::-webkit-scrollbar {
  display: none;
}
.ContactDetails_addBox__3K7gV {
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  /* height: 95%; */
  /* align-items: center;
  justify-content: space-evenly; */
  border: solid 0px;
  border-radius: 0.25rem;
  outline: none;
  width: 4rem;
  margin-bottom: 1rem;
}
.ContactDetails_addBoxBlk__2uq97 {
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  /* padding: 0.25rem 0.5rem; */
  /* height: 95%; */
  /* align-items: center;
  justify-content: space-evenly; */
  border: solid 0px;
  border-radius: 0.25rem;
  outline: none;
  width: 2.5rem;
  margin-bottom: 1rem;
}
.ContactDetails_backButtonContainer__3YKPV{
  width: 100%;
  /* border: 2px solid black;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start; */
  padding: 1rem 1rem 0 1rem;
}

.ContactButton_button__2snQ8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  outline: none;
  border: solid 1px;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 12px;
}

.AttachmentModal_overlay__Bc8E0 {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 4;
}
.AttachmentModal_dragBox__1CVdk {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  background-color: #f3f3f3;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.AttachmentModal_parent__2sey- {
  margin-top: 130px;

  width: 35vw;
  min-width: 450px;
  height: 50vh;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 10px; */
  background-color: white;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px; */
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.AttachmentModal_headingContainer__2oWJB {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.AttachmentModal_heading__SHTrm {
  margin: 0px;
  font-size: 23px;
  color: #272944;
  font-weight: 500;
}

.AttachmentModal_detailContainer__32hHI {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.AttachmentModal_image__SomBY {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  cursor: pointer;
  background-size: contain;
}
.AttachmentModal_imageStyle__2e_xQ{
  width: 300px;
  height: 200px;
  cursor: pointer;
  background-size: contain;
  margin-bottom:10px;
}

.AttachmentModal_name__2E9b_ {
  margin: 0px;
  font-size: 20px;
  margin-top: 5px;
}

.AttachmentModal_uploadContainer__2sBm7 {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}
.AttachmentModal_selectBox__2XwqU {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.AttachmentModal_select__3QQO1 {
  display: none;
}

.AttachmentModal_uploadButton__1Kx5n {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  /* padding: 7px 15px; */
  cursor: pointer;
  border: solid 0px;
  outline: none;
  /* border-radius: 5px;
  font-size: 19px;
  font-weight: 500; */
  border-radius: 0.25rem;
  padding: 0.5rem 2rem;
}

.AttachmentModal_uploadButton__1Kx5n:active {
  opacity: 0.5;
}

.AttachmentModal_buttonContainer__3XiIj {
  display: flex;
}

.AttachmentModal_button__1vPJW {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d51e1e;
  color: white;
  border: solid 0px;
  outline: none;
  /* font-weight: 700; */
  /* padding: 10px 35px; */
  border-radius: 0.25rem;
  padding: 0.35rem 2rem;
  /* border-radius: 5px; */
  font-size: 16px;
}

.EditContact_parent__3Ts-W {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  min-width: 900px;
  width: 100vw;
  min-height: 100%;
  /* min-height:808px; */
  /* height: 170vh; */
  box-sizing: border-box;
  /* padding: 1.5rem 2rem; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.EditContact_child__2ATJ2 {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* margin-left: 1%; */
  height: 100%;
  background-color: #f3f3f3 !important;
  margin-top: 10px;
  /* padding-left: 2%;
  padding-right: 2%; */
}
.EditContact_addBox__3Vg4j {
  display: flex;
  flex-direction: row;
  background-color: #272944;
  color: white;
  /* font-size: 13px; */
  font-weight: bold;
  /* padding: 2px 10px; */
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
  border: solid 0px;
  border-radius: 5px;
  outline: none;
  width: 50px;
}
.EditContact_overlay__2XmFp {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.EditContact_heading__e0eiV {
  /* font-size: 20px; */
  font-weight: bold;
  color: #272944;
  margin-top: 10px;
  margin-left: 10px;
}

.EditContact_title__1tBbZ {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin: 0px;
  margin-top: 3%;
}

.EditContact_one__3a1aZ {
  /* font-size: 15px; */
  font-weight: bold;
  margin: 0px;
}

.EditContact_two__8IIEe {
  /* font-size: 15px; */
  font-weight: bold;
  color: red;
  margin: 0px;
  margin-left: 2px;
}

.EditContact_select__pVJxS input {
  /* font-family: "Times New Roman"; */
  font-family: "Poppins" !important;
  font-size: 6px;
}
.EditContact_select__pVJxS{
  color: #000;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}

.EditContact_box2__3U81u {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-between; 
  /* margin-top: 20px; */
  /* padding-left: 10px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between; */
}

.EditContact_divide__1xQEu {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 30%;
}

.EditContact_button__2275N {
  background-color: #272944;
  /* font-size: 18px; */
  font-weight: bold;
  color: white;
  margin-top: 5%;
  margin-bottom: 10px;
  border: solid 0px;
  outline: none;
  /* width: 50%; */
  align-self: center;
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px; */
  width: 25%;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.EditContact_headerView__2FJev {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.EditContact_closeIcon__zhYkK {
  margin-left: auto;
  cursor: pointer;
}

.EditContact_closeIcon__zhYkK:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.EditContact_inputContainer__2SDzK{
  width: 150px;
}


.SignIn_container__2CkiV {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
  /* border:2px solid black; */
  /* align-items:center; */
  /* justify-content: flex-end; */
  /* overflow: hidden; */
  /* position: relative; */
}

.SignIn_box1__ZpBjU {
  /* flex: 1; */
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  width: 65%;
  /* border:2px solid black; */
  /* justify-content: center; */
  /* gap: 5rem; */
  /* position: relative; */
  /* border:2px solid black; */
  /* padding-right: 2rem; */
  /* background-image: url("../../Assets/Images/signin_background_latest.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; */
}

.SignIn_box2__2GnJS {
 /* display: flex; */
 align-items: center;
 justify-content: center;
 background-color: rgba(255, 255, 255, 0.9);
 box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
 width: 100%;
 max-width: 70%;
 min-width: 70%;
 border-radius: 2rem;
 padding: 1.25rem 2rem 1.25rem 2rem;
 min-height: 100%;
 height: -webkit-fit-content;
 height: -moz-fit-content;
 height: fit-content;
}
.SignIn_form__3SqJt{
  width: 100%;

  /* min-height: 100%; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.SignIn_inputs__3p9CB {
  width: 100%;
}

.SignIn_buttons__PdI3z {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
}

.SignIn_button__245iB {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  /* background-color:#272944; */
  background-color: #272944;
  color: white;
}

.SignIn_button__245iB:hover {
  cursor: pointer;
}

.SignIn_headerText__2OV2a {
  /* margin-top: -1rem; */
  color: #272944;
  font-weight: 500;
  font-size: "12px" !important;
  font-family: "Poppins";
}

.SignIn_header__TwJM1 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.SignIn_forgetPassword__GgSYb {
  color: #272944;
  font-weight: 500;
}

.SignIn_forgetPassword__GgSYb:hover {
  cursor: pointer;
}

.SignIn_logo__1F5nl {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
}

.SignIn_image__1X9C7 {
  margin-left: 1rem;
}

.SignIn_socialIcons__Drbyx {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 2rem; */
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #272944;
  font-weight: 500;
  margin-top: -0.75rem;
}

.SignIn_storeIcons__31cHL {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.SignIn_iconContainer1__1Gmdi {
  /* border:2px solid black; */
  padding: 0.30rem 1.5rem 0.30rem 1.5rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

.SignIn_iconContainer2__ytgBJ {
  /* border:2px solid black; */
  padding: 0.07rem 1.25rem 0.07rem 1.25rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

/* .blurryBg {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.623)),
    url(../../Assets/Images/signin_background_latest.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
.blurryBg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
} */

@media (max-width: 767px) {
  .SignIn_container__2CkiV {
    flex-direction: column;
  }

  .SignIn_child1__2RgY1 {
    display: none;
  }

  .SignIn_box__2RZKi {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .SignIn_header__TwJM1 {
    text-align: center;
  }

  .SignIn_buttons__PdI3z {
    flex-direction: column;
    align-items: center;
  }

  .SignIn_button__245iB {
    width: 100%;
  }
}
.SignInTextInput_input__14WT1 {
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    border: solid 1px;
    align-items: center;
    padding: 14px 10px 14px 7px;
    border-color: whitesmoke;
  
    margin-top: 3%;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  }
  
  .SignInTextInput_inputText__3hMYV {
    border: solid 0px;
    background-color: whitesmoke;
    padding-left: 10px;
    font-size: 13px;
    outline: none;
    width: 90%;
  }
  
  .SignInTextInput_error__173_G {
    margin: 0px;
    text-align: right;
    color: red;
    margin-top: 8px;
    font-size: 12px;
    margin-right: 10px;
  }
.PasswordInput_input__2SBJ_ {
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  border: solid 1px;
  align-items: center;
  padding: 12px 0px 12px 7px;
  border-color: whitesmoke;
  width: 100%;
  margin-top: 3%;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}

#PasswordInput_inputText__9ENKq {
  border: solid 0px;
  background-color: whitesmoke;
  padding-left: 10px;
  font-size: 13px;
  outline: none;
  width: 80%;
}

#PasswordInput_icon__20kI0 {
  height: 12px;
}

.PasswordInput_try__YGS7k {
  margin-left: auto;
  margin-right: 3%;
}

.PasswordInput_error__E-SIY {
  margin: 0px;
  text-align: right;
  color: red;
  margin-top: 8px;
  font-size: 12px;
  margin-right: 10px;
}
.ForgotPassword_container__eDRk0 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
  /* border:2px solid black; */
  /* align-items:center; */
  /* justify-content: flex-end; */
  /* overflow: hidden; */
  /* position: relative; */
}

.ForgotPassword_box1__13pEB {
  /* flex: 1; */
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  width: 65%;
  /* border:2px solid black; */
  /* justify-content: center; */
  /* gap: 5rem; */
  /* position: relative; */
  /* border:2px solid black; */
  /* padding-right: 2rem; */
  /* background-image: url("../../Assets/Images/signin_background_latest.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; */
}

.ForgotPassword_box2__3nlDJ {
 /* display: flex; */
 align-items: center;
 justify-content: center;
 background-color: rgba(255, 255, 255, 0.9);
 box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
 width: 35%;
 max-width: 70%;
 min-width: 70%;
 border-radius: 2rem;
 padding: 1.25rem 2rem 1.25rem 2rem;
 min-height: 95%;
 height: -webkit-fit-content;
 height: -moz-fit-content;
 height: fit-content;
}
.ForgotPassword_form__1Qiwg{
  width: 100%;

  /* min-height: 100%; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.ForgotPassword_inputs__2Uh90 {
  width: 100%;
}
.ForgotPassword_iconContainer1__2ZXkz {
  /* border:2px solid black; */
  padding: 0.30rem 1.5rem 0.30rem 1.5rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

.ForgotPassword_iconContainer2__6gMul {
  /* border:2px solid black; */
  padding: 0.07rem 1.25rem 0.07rem 1.25rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}
.ForgotPassword_buttons__3pebt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.ForgotPassword_button__SseFs {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  background-color:#272944;
  color: #fff;
}
.ForgotPassword_button__SseFs:hover {
  cursor: pointer;
}
.ForgotPassword_headerText__20oF7 {
  margin-top: -1rem;
  color: white;
  font-weight: 500;
}
.ForgotPassword_header__3Fnzd {
  margin-bottom: 2rem;
  text-align: center;
}
.ForgotPassword_forgetPassword__283gR {
  color: #272944;
}
.ForgotPassword_forgetPassword__283gR:hover {
  cursor: pointer;
}
.ForgotPassword_logo__3V1Gp {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
}
.ForgotPassword_image__2TfQQ {
  margin-left: 1rem;
}
.ForgotPassword_socialIcons__1s_BH {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 1.5rem; */
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #272944;
  font-weight: 500;
}
.ForgotPassword_forgetBox__7Uj-w {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.ForgotPassword_loginText__PUDuZ {
  color: white;
  font-weight: 500;
}
.ForgotPassword_loginText__PUDuZ:hover {
  cursor: pointer;
}
.ForgotPassword_storeIcons__18Xvh {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

@media (max-width: 767px) {
  .ForgotPassword_container__eDRk0 {
    flex-direction: column;
  }
  .ForgotPassword_child1__1XgTb {
    display: none;
  }
  .ForgotPassword_box__3G_No {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ForgotPassword_header__3Fnzd {
    text-align: center;
  }
  .ForgotPassword_buttons__3pebt {
    flex-direction: column;
    align-items: center;
  }
  .ForgotPassword_button__SseFs {
    width: 100%;
  }
}

.PasswordReset_container__fdVPA {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
}

.PasswordReset_box1__19fHR {
  height: 100%;
  width: 65%;
}

.PasswordReset_box2__2EwM3 {
 align-items: center;
 justify-content: center;
 background-color: rgba(255, 255, 255, 0.9);
 box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
 width: 35%;
 max-width: 70%;
 min-width: 70%;
 border-radius: 2rem;
 padding: 1.25rem 2rem 1.25rem 2rem;
 min-height: 95%;
 height: -webkit-fit-content;
 height: -moz-fit-content;
 height: fit-content;
}
.PasswordReset_form__3qOR9{
  width: 100%;
}

.PasswordReset_inputs__3KieM {
  width: 100%;
}
.PasswordReset_iconContainer1__PboHd {
  padding: 0.30rem 1.5rem 0.30rem 1.5rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

.PasswordReset_iconContainer2__38xtL {
  padding: 0.07rem 1.25rem 0.07rem 1.25rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}
.PasswordReset_buttons__2K_tH {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.PasswordReset_button__184fu {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  background-color:#272944;
  color: #fff;
}
.PasswordReset_button__184fu:hover {
  cursor: pointer;
}
.PasswordReset_headerText__3KyKr {
  margin-top: -1rem;
  color: white;
  font-weight: 500;
}
.PasswordReset_header__1iQoj {
  margin-bottom: 2rem;
  text-align: center;
}
.PasswordReset_forgetPassword__3gj-E {
  color: #272944;
}
.PasswordReset_forgetPassword__3gj-E:hover {
  cursor: pointer;
}
.PasswordReset_logo__1TnsP {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
}
.PasswordReset_image__3CiJS {
  margin-left: 1rem;
}
.PasswordReset_socialIcons__1mg_p {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #272944;
  font-weight: 500;
}
.PasswordReset_forgetBox__2T3Fs {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.PasswordReset_loginText__16dAm {
  color: white;
  font-weight: 500;
}
.PasswordReset_loginText__16dAm:hover {
  cursor: pointer;
}
.PasswordReset_storeIcons__Uwa_4 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 5%;
}

@media (max-width: 767px) {
  .PasswordReset_container__fdVPA {
    flex-direction: column;
  }
  .PasswordReset_child1__CRF06 {
    display: none;
  }
  .PasswordReset_box__3j-_k {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .PasswordReset_header__1iQoj {
    text-align: center;
  }
  .PasswordReset_buttons__2K_tH {
    flex-direction: column;
    align-items: center;
  }
  .PasswordReset_button__184fu {
    width: 100%;
  }
}
.PasswordReset_buttonView__1un2h {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.SignUp_container__2jxy2 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
  /* border:2px solid black; */
  /* align-items:center; */
  /* justify-content: flex-end; */
  /* overflow: hidden; */
  /* position: relative; */
}

.SignUp_box1__11qlJ {
  /* flex: 1; */
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  width: 60%;
  /* border:2px solid black; */
  /* justify-content: center; */
  /* gap: 5rem; */
  /* position: relative; */
  /* border:2px solid black; */
  /* padding-right: 2rem; */
  /* background-image: url("../../Assets/Images/signin_background_latest.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; */
}

.SignUp_box2__Xzrxu {
 /* display: flex; */
 align-items: center;
 justify-content: center;
 background-color: rgba(255, 255, 255, 0.9);
 box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
 width: 100%;
 max-width:70%;
 min-width: 70%;
 border-radius: 2rem;
 /* padding: 1.25rem 2rem 1rem 2rem; */
 /* padding: 1px 2px 3px 4px; */
 min-height: 100%;
 height: -webkit-fit-content;
 height: -moz-fit-content;
 height: fit-content;
}
.SignUp_form__3azzR{
  width: 100%;

  /* min-height: 100%; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.SignUp_inputssign__9U3ho {
  width: 100%;
}

.SignUp_buttons__1bcEJ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
}

.SignUp_button__3hKjB {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  background-color:#272944;
  color: #fff;
}

.SignUp_button__3hKjB:hover {
  cursor: pointer;
}

.SignUp_headerText__1dpMo {
  /* margin-top: -1rem; */
  color: #272944;
  font-weight: 500;
  font-size: "12px" !important;
  font-family: "Poppins";
}

.SignUp_header__1fJUD {
  margin-bottom: 1.5rem;
  text-align: center;
}

.SignUp_forgetPassword__FYBS7 {
  color: #272944;
  font-weight: 500;
}

.SignUp_forgetPassword__FYBS7:hover {
  cursor: pointer;
}

.SignUp_logo__3zSz3 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
}

.SignUp_image__3qt5m {
  margin-left: 1rem;
}

.SignUp_socialIcons__tHS8_ {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 2rem; */
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #272944;
  font-weight: 500;
  margin-top: -0.75rem;
}

.SignUp_storeIcons__snXeE {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.SignUp_iconContainer1__7fUKF {
  /* border:2px solid black; */
  padding: 0.30rem 1.5rem 0.30rem 1.5rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

.SignUp_iconContainer2__2FgcQ {
  /* border:2px solid black; */
  padding: 0.07rem 1.25rem 0.07rem 1.25rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

/* .blurryBg {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.623)),
    url(../../Assets/Images/signin_background_latest.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
.blurryBg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
} */

@media (max-width: 767px) {
  .SignUp_container__2jxy2 {
    flex-direction: column;
  }

  .SignUp_child1__2a6P2 {
    display: none;
  }

  .SignUp_box__1uWil {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .SignUp_header__1fJUD {
    text-align: center;
  }

  .SignUp_buttons__1bcEJ {
    flex-direction: column;
    align-items: center;
  }

  .SignUp_button__3hKjB {
    width: 100%;
  }
}

.SignUp_input__3RhLQ {
  display: flex;
  flex-direction: row;
  /* background-color: whitesmoke; */
  border: solid 1px;
  align-items: center;
  padding: 10px 8px 10px 15px;
  border-color: whitesmoke;

  margin-top: 3%;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}

.SignUp_inputTextType__ojOZb {
  border: solid 0px;
  /* background-color: whitesmoke; */
  padding-left: 10px;
  font-size: 13px;
  outline: none;
  width: 90%;
}
.SignUp_inputTextdrop__2YTK4 {
  border: solid 0px;
  /* background-color: whitesmoke; */
  font-size: 13px;
  outline: none;
  width: 87%;
}

.SignUp_error__A9b4U {
  margin: 0px;
  text-align: right;
  color: red;
  margin-top: 8px;
  font-size: 12px;
  margin-right: 10px;
}
.TextInput_input__RwdaH {
  /* display: flex;
  flex-direction: row; */
  /* background-color: whitesmoke; */
  /* border: solid 1px; */
  align-items: center;
  /* padding: 14px 10px 14px 7px;
  border-color: whitesmoke; */

  /* margin-top: 3%; */
  /* border-radius: 5px; */
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19); */
}

.TextInput_inputText__1Serx {
  border: solid 0px;
  background-color: whitesmoke;
  padding-left: 10px;
  font-size: 13px;
  outline: none;
  width: 90%;
}

.TextInput_error__2fLB9 {
  margin: 0px;
  text-align: left;
  color: red;
  /* margin-top: 5px; */
  font-size: 12px;
  margin-left: 10px;
}





:root {
  --PRIMARY: "#272944";
  --PRIMARY_LIGHT: "#272944";
  --SECONDARY: "#71cacc";
  --HEADING: "#191919";
  --TABLEHEAD: "#484b49";
  --BACKGROUND: "white"!important;
}

/* const theme = {
  colors: {
    PRIMARY: '#272944',
    PRIMARY_LIGHT: '#71CACC',
    GREY: '#606060',
    GREY_LIGHT: '#60606080',
    GREY_BACKGROUND: '#F2F2F2',
    ERROR: '#B00020',
    HEADINGS: '#272944',
    GREEN: '#4CCC47',
    BLUE: '#2FA2D3',
    RED: '#D51E1E',
    USER: '#207D7D',
    ORANGE: '#ff9933',
    PLACEHOLDER: '#00000040',
  },
  nav_colors: {
    PRIMARY: '#272944',
    INACTIVE: '#BBBBBB',
  },
  logo_colors: {
    WHATSAPP: '#4FCE5D',
    PHONE: '#63AF5C',
    MAIL: '#1D7DB1',
    ADD: '#272944',
  },
  notes: {
    PINK: '#FFC6C6',
    YELLOW: '#FFEDBB',
    GREEN: '#E8FFC6',
  },
  agenda: {
    PRIMARY: '#299696',
    PENDING: '#fdb228',
  },
}; */

