.parent {
  display: flex;
  flex-direction: column;
  width: 45vw;
  background-color: white;
  height: 50vh;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding: 25px 25px;
  box-sizing: border-box;
}
.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.title {
  margin: 0px;
  font-size: 19px;
  font-weight: 600;
}

.dragBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  /* background-color: #f3f3f3; */
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.cancelButton {
  display: flex;
  background-color: red;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 14px;
  outline: none;
  margin-left: auto;
  /* margin-right: 15px; */
  border: solid 0px;
}
.selectBox {
  display: flex;

  justify-content: center;
}

.select {
  display: none;
}

.uploadButton {
  display: flex;
  background-color: #272944;
  color: #ffffff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-left: 1rem;
}

.uploadButton:active {
  opacity: 0.5;
}
