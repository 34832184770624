.parent {
  display: flex;
  flex-direction: column;
  width: 30vw;
  min-width: 250px;
  background-color: white;
  height: 520px;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.headContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.searchBox {
  display: flex;
  flex-direction: row;
  border: solid 1.5px #272944;
  background-color: #fff;
  border-radius: 15px;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  margin-top: 0.5rem;
}

.searchText {
  display: flex;
  border: solid 0px;
  outline: none;
}

.manageText {
  font-weight: 500;
  /* color: #272944;
  font-size: 17px; */
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 5px;
  margin-top: 15px;

  height: 350px;
  overflow-y: auto;
}

.text {
  margin: 0px;
  margin-left: 5px;
  /* font-size: 14px; */
}

.checkbox {
  margin-top: 5px;
  accent-color: #272944 !important;
}

.child {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.saveButton {
  display: flex;
  align-items: center;
  background-color: #272944;;
  border: solid 0px;
  outline: none;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
}

.cancelButton {
  display: flex;
  align-items: center;
  background-color: #d51e1e;
  border: solid 0px;
  outline: none;
  color: white;
  padding: 5px 13px;
  border-radius: 5px;
}

.resetButton {
  display: flex;
  align-items: center;
  background-color: #0a6085;
  border: solid 0px;
  outline: none;
  color: white;
  padding: 5px 13px;
  border-radius: 5px;
}

.showColumnAnim {
  animation: showColumnManager 0.2s ease-in;
  margin-top: 65px;
  border-radius: 10px;
}

@keyframes showColumnManager {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 500px;
    opacity: 1;
  }
}
